import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LanguageQuery } from '@b3networks/api/portal';

// Translate language
@Pipe({
  name: 'translate',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class TranslatePipe implements PipeTransform {
  constructor(private languageQuery: LanguageQuery) {}

  transform(key: string): any {
    if (!key) {
      return;
    }
    return this.languageQuery.translate(key) || key;
  }
}
