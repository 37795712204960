<div class="flex justify-between items-center">
  <h1 mat-dialog-title>Select Organization</h1>
  <mat-icon mat-dialog-title mat-dialog-close *ngIf="!data?.disabledClose" class="cursor-pointer">close</mat-icon>
</div>

<mat-dialog-content>
  <div [formGroup]="filterFG" class="pb-6 flex flex-col gap-4">
    <mat-radio-group
      class="flex justify-between items-center"
      *ngIf="hasServicedOrg$ | async"
      formControlName="type"
      aria-label="Select an option"
    >
      <mat-radio-button [value]="OrgType.memberOrg">Your Organizations</mat-radio-button>
      <mat-radio-button [value]="OrgType.servicedOrg">Serviced Organizations</mat-radio-button>
    </mat-radio-group>
    <mat-form-field *ngIf="shouldShowSearchBox" class="no-validator">
      <input matInput formControlName="queryString" placeholder="Search organization" />
    </mat-form-field>
    <div *ngIf="organizations.length; else emptyOrgs" class="flex flex-col mt-4">
      <div
        class="org-item"
        *ngFor="let org of organizations; trackBy: trackBy"
        (click)="isServicedOrg && orgExisted(org) ? null : switchOrg(org)"
        [ngClass]="{
          'org-item': !(isServicedOrg && orgExisted(org)),
          'org-item-existed': isServicedOrg && orgExisted(org)
        }"
        [class.active]="org.orgUuid === selectedOrgUuid"
      >
        <div class="flex items-center gap-4">
          <img [src]="org.photoSrc" class="size-[50px]" />
          <span>{{ org.orgName }}</span>
        </div>

        <button *ngIf="org.isPartner" mat-flat-button color="primary">Admin</button>
        <button *ngIf="org.type === 'DEMO'" mat-flat-button class="linkDemo">Demo</button>
        <mat-icon *ngIf="isServicedOrg && orgExisted(org)" matTooltip="You are already a member of this account.">
          help
        </mat-icon>
      </div>
    </div>
  </div>
</mat-dialog-content>

<ng-template #emptyOrgs>
  <div class="text-center">No organization found</div>
</ng-template>
