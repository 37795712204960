import { Inject, Injectable, Optional } from '@angular/core';
import {
  DEFAULT_API_PROXY,
  DOMAIN_ALLOW_TELCO_FLOW,
  INJECT_PORTAL_DOMAIN,
  TESTING_DOMAIN,
  TESTING_UAT_DOMAINS
} from './constants/constants';
import { logger } from './logger/logging';
import { isLocalhost } from './utils/plain_func.util';

@Injectable({ providedIn: 'root' })
export class DomainUtilsService {
  private _apiUrl = location.origin + '/_a';
  private _storageBackdoorAPIUrl = location.origin + '/_b';

  get apiUrl() {
    return this._apiUrl;
  }

  get portalDomain() {
    logger.debug('portalDomain');

    let domain = window.location.hostname;
    if (isLocalhost()) {
      domain = this._portalDomain || atob(TESTING_DOMAIN);
    }
    return domain;
  }

  get storageBackdoorAPIUrl() {
    return this._storageBackdoorAPIUrl;
  }

  get domainPortal() {
    let domain = window.location.hostname;
    if (isLocalhost()) {
      domain = this._portalDomain || atob(TESTING_DOMAIN);
    }
    return domain;
  }

  get isTestingDomain() {
    return this.domainPortal === atob(TESTING_DOMAIN);
  }

  get isNewPortal() {
    return DOMAIN_ALLOW_TELCO_FLOW.includes(window.location.hostname);
  }

  constructor(@Optional() @Inject(INJECT_PORTAL_DOMAIN) private _portalDomain) {
    if (isLocalhost()) {
      if (TESTING_UAT_DOMAINS.includes(btoa(this._portalDomain))) {
        this._apiUrl = `https://${this._portalDomain}/_ac`;
        this._storageBackdoorAPIUrl = `https://${this._portalDomain}/_b`;
      } else if (this._portalDomain && this._portalDomain !== 'b3fone.com') {
        this._apiUrl = `https://${this._portalDomain}/_ac`;
        this._storageBackdoorAPIUrl = `https://${this._portalDomain}/_b`;
      } else {
        this._apiUrl = atob(DEFAULT_API_PROXY) + `/_ac`;
        this._storageBackdoorAPIUrl = atob(DEFAULT_API_PROXY) + `/_b`;
      }
    }
  }

  getPortalDomain() {
    return this.domainPortal;
  }
}
