import { b3nColorPalettes, getHexTransparencies } from '@b3networks/shared/ui/theme';

export const THEME_LIGHT_MODE = {
  b3n: {
    // required
    background: '#FFFFFF',
    'background-v1': b3nColorPalettes.white.DEFAULT,
    color: b3nColorPalettes.black[904], // '#26272c'
    'secondary-text': '#0000008a',
    'box-uuid': b3nColorPalettes.gray[919],
    selection: {
      active: '#EBF2FB',
      'text-active': '#136899', // same b3n-color
      hover: '#F2F3F5',
      'text-hover': b3nColorPalettes.black[904]
    },
    highlight: b3nColorPalettes.blue[150],
    link: b3nColorPalettes.blue[100],
    disabled: getHexTransparencies(b3nColorPalettes.black[904], 40), // #26272c66
    hover: b3nColorPalettes.blue[150], //  '#f2f3f5'

    // optional
    'secondary-color': getHexTransparencies(b3nColorPalettes.black[904], 80), // #26272cCC
    border: b3nColorPalettes.gray[300], //  '#e0e0e0'
    'border-2': getHexTransparencies(b3nColorPalettes.black.DEFAULT, 42),
    shadow: getHexTransparencies(b3nColorPalettes.black.DEFAULT, 10),
    'scrollbar-thumb': getHexTransparencies(b3nColorPalettes.blue[650], 20),
    'scrollbar-track': b3nColorPalettes.gray[150],
    contrast: b3nColorPalettes.black.DEFAULT, // #000000
    title: b3nColorPalettes.black[904],
    sidebar: {
      'user-menu': b3nColorPalettes.white.DEFAULT,
      'hover-menu': '#f2f3f5',
      'user-menu-chip': b3nColorPalettes.gray[919],
      'org-section': '#F6F8FC',
      'org-menu-item-hover': '#EBEDEF',
      'box-uuid-color': '#26272c',
      background: '#1979B11A',
      'hover-item': b3nColorPalettes.gray[200]
    },
    home: {
      bg: b3nColorPalettes.blue[250],
      announcement: b3nColorPalettes.white.DEFAULT
    },
    sidenav: {
      item: {
        hover: '#D6E6FC',
        active: '#D6E6FC'
      },
      icon: {
        color: b3nColorPalettes.black[904],
        active: b3nColorPalettes.blue[100]
      }
    },
    icon: {
      color: b3nColorPalettes.black[904],
      action: b3nColorPalettes.gray[915],
      active: b3nColorPalettes.gray[919],
      disabled: getHexTransparencies(b3nColorPalettes.black[904], 10)
    },
    conversation: {
      header: {
        title: b3nColorPalettes.black[904],
        subtitle: b3nColorPalettes.gray[700]
      },
      message: {
        mention: getHexTransparencies(b3nColorPalettes.blue[100], 10),
        highlight: b3nColorPalettes.yellow[300],
        editing: getHexTransparencies(b3nColorPalettes.gray[300], 50)
      },

      mention: {
        background: b3nColorPalettes.white.DEFAULT,
        'not-member': getHexTransparencies(b3nColorPalettes.black.DEFAULT, 10)
      },
      footer: {
        bg: b3nColorPalettes.gray[150],
        'delete-bg': b3nColorPalettes.red[600]
      },
      preview: {
        background: getHexTransparencies(b3nColorPalettes.gray[906], 55)
      },
      quote: b3nColorPalettes.red[450]
    },
    'landing-page': {
      'org-title': getHexTransparencies(b3nColorPalettes.black.DEFAULT, 67),
      'last-login-color': b3nColorPalettes.gray[700]
    },
    toast: {
      success: {
        color: b3nColorPalettes.green[500],
        bg: b3nColorPalettes.green[600],
        border: ''
      },
      warning: {
        color: b3nColorPalettes.yellow[700],
        bg: b3nColorPalettes.yellow[600],
        border: ''
      },
      error: {
        color: b3nColorPalettes.red[800],
        bg: b3nColorPalettes.red[900],
        border: ''
      }
    }
  },
  'primary-a-tag': b3nColorPalettes.blue[70]
};

// a: {
//   b: {
//     c: {
//       d: {
//         f: 'f-value'
//       }
//     },
//     i: 'i-value'
//   },
//   e: 'e-value'
// }
// result: ==========
// "--a-b-c-d-f": "f-value",
// "--a-b-i": "i-value",
// "--a-e": "e-value"
