import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SuppliersState, SuppliersStore } from './supplier.store';

@Injectable({ providedIn: 'root' })
export class SuppliersQuery extends QueryEntity<SuppliersState> {
  constructor(protected override store: SuppliersStore) {
    super(store);
  }

  selectSuppliersByOrgUuid(supplierOrgUuid: string) {
    return this.selectAll({
      filterBy: entity => entity.supplierOrgUuid === supplierOrgUuid
    });
  }
}
