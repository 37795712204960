<h1 mat-dialog-title>Recovery Key</h1>

<div mat-dialog-content>
  <div class="flex flex-col gap-4">
    <p>The Recovery Key allows account access if Two-Factor Authentication code cannot be generated</p>
    <h1 class="text-center">{{ recoveryKey }}</h1>
    <div class="flex gap-1 bg-warning">
      <mat-icon>info_outline</mat-icon>
      <div>
        <p>Treat your Recovery Key with the same level of attention as your password.</p>
        <p>Remember to download and securely store it.</p>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="dialogRef.close(true)">Close</button>
  <button mat-flat-button color="primary" (click)="download()">Download</button>
</div>
