import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  IdentityProfileQuery,
  ProfileOrg,
  SecurityComplianceQuery,
  SecurityService,
  TfaInfoQuery,
  TfaService
} from '@b3networks/api/auth';
import { AudioPlayerService, AudioWebRTC, TypeSound } from '@b3networks/api/call';
import { ChatService, ChatTopic, SocketStatus, TimeService } from '@b3networks/api/chat';
import { Pageable } from '@b3networks/api/common';
import { Contact, ContactService } from '@b3networks/api/contact';
import { Notification, NotificationType, NotificationsService, QueryNotificationReq } from '@b3networks/api/inbox';
import { PartnerQuery, PartnerService, PortalConfigQuery, PortalConfigService } from '@b3networks/api/partner';
import { PersonalSettingsQuery, PersonalSettingsService } from '@b3networks/api/portal';
import { TxnNotificationComponent, TxnNotificationModel } from '@b3networks/chat/shared/txn-notification';
import {
  AppStateQuery,
  AppStateService,
  ApplicationQuery,
  ApplicationService,
  RenderStatus,
  SessionQuery,
  SessionService
} from '@b3networks/portal/base/shared';
import {
  EventMapName,
  PORTAL_BASE_HANDLE_WS,
  UUID_V4_REGEX,
  WindownActiveService,
  X,
  buildUrlParameter,
  logger
} from '@b3networks/shared/common';
import { BrowserNotificationService, NotificationRouterLink, Permission } from '@b3networks/shared/notification';
import { ThemeName, ThemeService } from '@b3networks/shared/ui/theme';
import { ToastData, ToastService } from '@b3networks/shared/ui/toast';
import { HashMap } from '@datorama/akita';
import { differenceInSeconds } from 'date-fns';
import { Observable, Subject, Subscription, combineLatest, forkJoin, lastValueFrom, timer } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { MainViewComponent } from './main-view/main-view.component';
import { SwitchOrganizationData, SwitchOrganizationDialog } from './shared/modal/switch-org/switch-org.component';
import { NotificationReceiveProcessor } from './shared/service/notification-receive-processor.service';
import { WindowMessageService } from './shared/service/window-message.service';

declare const Favico: any;

const TIME_TO_CHECK_SESSION_IN_SECONDS = 15;

@Component({
  selector: 'b3n-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('txnNotification') txnNotification: TemplateRef<TxnNotificationComponent>;

  readonly SocketStatus = SocketStatus;
  loading: boolean;
  isLoggedIn: boolean;
  showSidebar = false;
  loginAsData: HashMap<any>;

  sessionExpiryTimerSub$: Subscription;

  favicon: any;

  socketStatus$: Observable<SocketStatus>;

  isDarkMode$ = this.themeService.isDarkMode$;

  // save uuid notify
  notifyCache: Map<string, string | number> = new Map<string, string | number>();

  private isSupportWS: boolean;
  private isSupportNotifcationTxn: boolean;

  private _listNotifyShowed: HashMap<ToastData> = {};
  private _isInactiveWindow = false;

  private _triggerCheckWs = new Subject();
  private _destroyWebsocket$ = new Subject();
  private _destroyNavigationEnd$ = new Subject();
  private _destroySessionToken$ = new Subject();

  get isLoginAsSession(): boolean {
    return this.loginAsData != null && !!this.loginAsData['orgUuid'];
  }

  constructor(
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private tfaQuery: TfaInfoQuery,
    private tfaService: TfaService,
    private securityQuery: SecurityComplianceQuery,
    private securityService: SecurityService,
    private applicationQuery: ApplicationQuery,
    private applicationService: ApplicationService,
    private personalSettingQuery: PersonalSettingsQuery,
    private personalSettingService: PersonalSettingsService,
    private appStateQuery: AppStateQuery,
    private appStateService: AppStateService,
    private partnerService: PartnerService,
    private partnerQuery: PartnerQuery,
    private portalConfigQuery: PortalConfigQuery,
    private portalConfigService: PortalConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private windowService: WindowMessageService,
    private browserNotification: BrowserNotificationService,
    private title: Title,
    private dialog: MatDialog,
    private chatService: ChatService,
    private windownActiveService: WindownActiveService,
    private timeService: TimeService,
    private notificationsService: NotificationsService,
    private toastService: ToastService,
    private contactService: ContactService,
    private notificationReceiveProcessor: NotificationReceiveProcessor,
    private themeService: ThemeService,
    private audioPlayerService: AudioPlayerService,
    private identityProfileQuery: IdentityProfileQuery
  ) {
    this.themeService.generateClassWithTheme('theme-default', ThemeName.lightMode);
    this.themeService.generateClassWithTheme('dark-theme-portal-base', ThemeName.darkMode);

    sessionStorage.setItem(PORTAL_BASE_HANDLE_WS, 'true');
    this.isSupportWS = true;
    this.isSupportNotifcationTxn = this.windowService.isSupportNotifcationTxn;

    this.windownActiveService.init();

    // generate tabUuid for log ws
    window['tabUuid'] = new Date().getTime();
    this.chatService.tabUuid = window?.['tabUuid'] ? 'PORTAL_' + window?.['tabUuid'] : null;

    logger.info('Start Portal Base with tabUuid: ', window?.['tabUuid']);

    this.loginAsData = buildUrlParameter();
    this._handleNavigateEvents();
    this._initNonSessionData(); // this request don't need session at all
    this._importFileAudios();
    this.windowService.init();
  }

  ngOnInit() {
    this._destroySessionToken$.next(true); // completed current subscriber and

    const loadedapps = this.applicationQuery.allRenderedApplications;
    if (loadedapps && loadedapps.length) {
      this.applicationService.closeApps(loadedapps);
    }
    this.sessionQuery.isLoggedIn$.pipe(distinctUntilChanged()).subscribe(async loggedIn => {
      logger.info(`Handle logged in status changed to: ${loggedIn}`);

      if (loggedIn) {
        this.isLoggedIn = true;
        this.processValidSession();
      } else {
        let hasValidSession = false;
        if (!this.sessionQuery.isValidatedSession) {
          hasValidSession = await lastValueFrom(this.sessionService.checkSessionExpiry());
          logger.info(
            `Current session is invalid and tried to recheck session validity again and result is: ${hasValidSession}`
          );
        }

        if (!hasValidSession && this.sessionQuery.hasRememberMe) {
          const refreshed = await this.sessionService.refreshSession('handle logged in status changed');
          if (!refreshed) {
            if (this.isSupportWS) {
              this.destroyOldWebsocket();
              this.chatService.normalClose();
            }
            this._go2loginPage();
          }
        } else if (!hasValidSession) {
          if (this.isSupportWS) {
            this.destroyOldWebsocket();
            this.chatService.normalClose();
          }
          this._go2loginPage();
        }
      }
    });

    this.setFavicon();
    this.addStripeJs();
  }

  ngOnDestroy() {
    this._destroyNavigationEnd$.next(true);
    this._destroyNavigationEnd$.complete();

    this._destroySessionToken$.next(true);
    this._destroySessionToken$.complete();

    this._destroyWebsocket$.next(true);
    this._destroyWebsocket$.complete();

    this._triggerCheckWs.next(true);
    this._triggerCheckWs.complete();
  }

  manualConnect() {
    this.chatService.reconnect({ forceReset: true, reason: 'manual Connect' });
  }

  private _importFileAudios() {
    const files = [
      <AudioWebRTC>{
        name: TypeSound.notify,
        audio: new Audio('assets/sound/ringtone-livechat.wav')
      }
    ];

    this.audioPlayerService.importFileAudios(files);
  }

  private processValidSession() {
    this.appStateQuery.loading$.pipe(distinctUntilChanged(), takeUntil(this._destroySessionToken$)).subscribe(value => {
      setTimeout(() => {
        this.loading = value;
      }, 0);
    });

    this.appStateQuery.showSidebar$.pipe(takeUntil(this._destroySessionToken$)).subscribe(setting => {
      this.showSidebar = setting;
    });

    this.watchUserProfile();
    this.watchCompliantInfo();
    this.initPortalSettings();
    this.registerNotification();

    this.sessionExpiryTimerSub$ = timer(
      TIME_TO_CHECK_SESSION_IN_SECONDS * 1000,
      TIME_TO_CHECK_SESSION_IN_SECONDS * 1000
    )
      .pipe(takeUntil(this._destroySessionToken$))
      .subscribe(async () => {
        const expiryInSeconds = differenceInSeconds(this.sessionQuery.getValue().sessionExpipryAt, new Date());

        if (expiryInSeconds <= TIME_TO_CHECK_SESSION_IN_SECONDS) {
          if (this.sessionQuery.hasRememberMe) {
            // do for first time go to app
            // cannot do at constructor on session service when it's conflict with interceptor
            await this.sessionService.refreshSession('check session expiry');
          } else {
            setTimeout(
              async () => {
                await this.sessionService.checkSessionExpiry();
              },
              (expiryInSeconds + 1) * 1000
            );
            this.sessionExpiryTimerSub$.unsubscribe();
          }
        }
      });
  }

  private watchUserProfile() {
    this.sessionQuery.profile$
      .pipe(
        filter(profile => profile != null),
        takeUntil(this._destroySessionToken$)
      )
      .subscribe(profile => {
        if (profile.organizations.length === 0) {
          this.router.navigate(['account']);
        }
      });

    this.sessionQuery.currentOrg$
      .pipe(
        filter(org => org != null),
        takeUntil(this._destroySessionToken$)
      )
      .subscribe(org => {
        this.updatePageTitle();
        if (this.isSupportWS) {
          this.initChat(org);
        }

        // TODO need verify this flow. When user switch org, we don't reload the app.
        // So it should keep ws connection and broadcast message to child iframe
        // Then we need place to keep ws connection
        const subscription = this.applicationQuery
          .selectCountChatApplications(org.orgUuid)
          .pipe(takeUntil(this._destroySessionToken$))
          .subscribe(count => {
            if (count > 0) {
              setTimeout(() => {
                if (subscription) {
                  subscription.unsubscribe();
                }
              }, 0);
            }
          });

        // wait favicon add header
        this.partnerQuery.partner$
          .pipe(
            filter(partner => !!partner?.domainName),
            take(1),
            switchMap(() => timer(1000)),
            takeUntil(this._destroySessionToken$)
          )
          .subscribe(() => {
            this.applicationQuery
              .selectNotificationCount(org.orgUuid)
              .pipe(takeUntil(this._destroySessionToken$), distinctUntilChanged())
              .subscribe(totalCount => {
                if (this.favicon) {
                  if (totalCount > 9) {
                    this.favicon.badge(`9+`);
                  } else if (totalCount > 0) {
                    this.favicon.badge(totalCount);
                  } else {
                    this.favicon.reset();
                  }
                }
              });
          });
      });

    this.sessionService
      .getProfile()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(profile => {
        let userNotBelongSpecificOrg: boolean;
        let currentOrg: ProfileOrg;

        const url = this.router.url;
        const rs: RegExpMatchArray = url.match(UUID_V4_REGEX);
        if (rs && rs.index === 1) {
          const orgUuid = url.substring(1, 37);

          if (orgUuid) {
            currentOrg =
              profile.organizations.find(o => o.orgUuid === orgUuid) ||
              this.sessionQuery.getValue().servicedOrgs.find(o => o.orgUuid === orgUuid);
          }

          userNotBelongSpecificOrg = currentOrg == null;
          logger.info(
            `Detected user start with specific org ${orgUuid} and find out him ${
              userNotBelongSpecificOrg ? ' not belong to this org' : 'belong to this org'
            }`
          );
        } else if (this.isLoginAsSession) {
          currentOrg = profile.organizations.find(o => o.orgUuid === this.loginAsData['orgUuid']);
          logger.info(
            `Detected admin start to login as to org ${this.loginAsData['orgUuid']} and find out him ${
              currentOrg == null ? ' not belong to this org' : 'belong to this org'
            }`
          );
        }
        if (userNotBelongSpecificOrg) {
          logger.info(`User has only one org, navigate to this org now...`);
          this.router.navigate(['access-denied']);
        } else if (
          !currentOrg &&
          (profile.organizations.length === 1 ||
            (profile.organizations.length === 0 && this.sessionQuery.getValue().servicedOrgs.length === 1))
        ) {
          logger.info(`User has only one org, navigate to this org now...`);
          currentOrg = profile.organizations[0] || this.sessionQuery.getValue().servicedOrgs[0];
          const appendedParams = url.split('/').filter(param => !!param && param !== currentOrg.orgUuid);
          this.router.navigate([currentOrg.orgUuid, appendedParams]);
        } else if (
          !currentOrg &&
          (profile.organizations.length > 1 || this.sessionQuery.getValue().servicedOrgs.length > 1) &&
          !location.hash.includes('/error') &&
          !location.hash.includes('/access-denied') &&
          !location.hash.includes('/security-check') &&
          !location.hash.includes('/auth')
        ) {
          logger.info('User belong to multiple orgs, let user select one...');

          forkJoin([this.tfaService.get2FaInfo(), this.securityService.getSecurityCompliance()]).subscribe(
            ([tfa, compliant]) => {
              if (compliant.tfaRequired && !tfa.tfaEnabled) {
                if (this.router.url.indexOf('security-check') === -1) {
                  this.router.navigate(['security-check']);
                }
              } else {
                this.dialog.open(SwitchOrganizationDialog, {
                  width: '500px',
                  disableClose: true,
                  data: <SwitchOrganizationData>{
                    disabledClose: true,
                    activeApp: location.hash?.substring('#/'.length)
                  }
                });
              }
            }
          );
        } else if (currentOrg && this.isLoginAsSession) {
          logger.info(`Navigate to login as org`);
          this.router.navigate([currentOrg.orgUuid, 'home']);
        }

        if (currentOrg) {
          this.sessionService.switchOrg(currentOrg);
        }
      });
  }

  private updatePageTitle() {
    const data = [];
    if (this.sessionQuery.currentOrg?.orgShortName) {
      data.push(this.sessionQuery.currentOrg?.orgShortName);
    }
    let child = this.activatedRoute.firstChild;

    if (!child && !!this.activatedRoute.snapshot.data['title']) {
      data.push(this.activatedRoute.snapshot.data['title']);
    } else {
      while (child?.firstChild) {
        child = child?.firstChild;
      }

      if (child.snapshot.data['title']) {
        data.push(child.snapshot.data['title']);
      }
    }

    const title = data.join(' | ');
    if (title) {
      this.title.setTitle(title);
    }
  }

  private watchCompliantInfo() {
    combineLatest([this.sessionQuery.currentOrg$, this.tfaQuery.tfaInfo$, this.securityQuery.securityCompliance$])
      .pipe(
        takeUntil(this._destroySessionToken$),
        filter(
          ([currentOrg, tfa, compliant]) =>
            currentOrg != null && Object.keys(tfa).length > 0 && Object.keys(compliant).length > 0
        ),
        distinctUntilChanged()
      )
      .subscribe(([currentOrg, tfa, compliant]) => {
        if (
          (currentOrg.isPartner && !tfa.tfaEnabled) ||
          (compliant.isActive && (compliant.passwordUpdateRequired || (compliant.tfaRequired && !tfa.tfaEnabled)))
        ) {
          setTimeout(() => {
            if (this.router.url.indexOf('security-check') === -1) {
              this.router.navigate(['security-check']);
            }
          }, 0);
          this.appStateService.toggleAppLoading(false);
          this.appStateService.toggleSidebar(false);
        } else if (this.showSidebar) {
          this.appStateService.toggleSidebar(this.showSidebar);
        }
      });

    this.sessionQuery.currentOrg$
      .pipe(
        filter(org => org != null),
        takeUntil(this._destroySessionToken$)
      )
      .subscribe(() => {
        forkJoin([this.tfaService.get2FaInfo(), this.securityService.getSecurityCompliance()]).subscribe();
      });
  }

  private registerNotification() {
    this.browserNotification.requestPermission().subscribe({
      next: () => {},
      error: err => console.error(err)
    });
  }

  private initPortalSettings() {
    this.personalSettingQuery.darkMode$
      .pipe(
        takeUntil(this._destroySessionToken$),
        filter(settings => settings != null)
      )
      .subscribe(isDarkmode => {
        const name = isDarkmode ? ThemeName.darkMode : ThemeName.lightMode;
        this.themeService.toggleDarkMode(name);
      });

    this.personalSettingService.getPersonalSettings().subscribe();
  }

  private destroyOldWebsocket() {
    this._destroyWebsocket$.next(true);
    this._destroyWebsocket$.complete();
    this._destroyWebsocket$ = new Subject();
  }

  private initChat(org: ProfileOrg) {
    this.destroyOldWebsocket();

    // handle reconnect ws successfully
    let tapFirst = true;
    this.chatService.socketStatus$
      .pipe(
        filter(status => status === SocketStatus.opened),
        takeUntil(this._destroyWebsocket$)
      )
      .subscribe(() => {
        this.fetchDataInitChat();

        if (tapFirst) {
          // health check
          combineLatest([timer(5000, 10000), this._triggerCheckWs.asObservable().pipe(startWith(true))])
            .pipe(
              debounceTime(1000),
              filter(() => this.windownActiveService.windowActiveStatus),
              takeUntil(this._destroyWebsocket$)
            )
            .subscribe(() => {
              // check invaild-token ws
              this.timeService.getTsTime().subscribe({
                next: () => {},
                error: (err: HttpErrorResponse) => {
                  if (err?.status === 502 || (err?.status === 401 && err?.error === 'invalid-token')) {
                    this.chatService.clearWs();
                    this.chatService
                      .initChat({
                        orgUuid: X.orgUuid,
                        bypass: true,
                        isV2: true,
                        reason: 'portal-interval-30s-401'
                      })
                      .subscribe();
                  }
                }
              });
            });
        }

        tapFirst = false;
      });

    setTimeout(() => {
      this.windownActiveService.windowActiveStatus$.pipe(takeUntil(this._destroyWebsocket$)).subscribe(status => {
        if (this._isInactiveWindow && status) {
          if (this.chatService.state.socketStatus === SocketStatus.closed) {
            logger.info('_triggerCheckStatusWs: ');
            this._triggerCheckWs.next(true);
          }
        }
        this._isInactiveWindow = !status;
      });
    }, 5000);

    this.chatService
      .initChat({ orgUuid: org.orgUuid, isV2: true })
      .pipe(takeUntil(this._destroyWebsocket$))
      .subscribe();

    this.chatService.session$.pipe(takeUntil(this._destroyWebsocket$)).subscribe(session => {
      if (session) {
        this.windowService.fireEventAllRegisters(EventMapName.onSession, session, org.orgUuid);
      }
    });

    this.chatService
      .onmessage()
      .pipe(takeUntil(this._destroyWebsocket$))
      .subscribe(message => {
        this.windowService.fireEventOnMessage(message, org.orgUuid);
        this.notificationReceiveProcessor.process(message);
      });

    this.chatService.socketStatus$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._destroyWebsocket$),
        tap(status => {
          this.windowService.fireEventAllRegisters(EventMapName.socketStatus, status, org.orgUuid);
        })
      )
      .subscribe();
  }

  private fetchDataInitChat() {
    logger.info('fetchDataInitChat: ');
    if (this.sessionQuery?.currentOrg?.orgUuid) {
      const topic = [...new Set(this.windowService.msgTopics[this.sessionQuery.currentOrg.orgUuid])];

      if (this.isSupportNotifcationTxn) {
        topic.push(ChatTopic.OMNI);
      }

      if (topic.length > 0) {
        this.chatService.subscribeTopic(topic).subscribe(() => {
          if (this.isSupportNotifcationTxn) {
            this.getNotificationTxn();
          }
        });
      }
    }
  }

  private _initNonSessionData() {
    this.partnerService.getPartnerByDomain().subscribe();
    this.portalConfigService.getPortalConfig().subscribe();

    this._registerCustomIcons();
  }

  private _registerCustomIcons() {
    const icons = [
      { name: 'pin', icon: 'assets/icons/pin.svg' },
      { name: 'unpin', icon: 'assets/icons/unpin.svg' },
      { name: 'user', icon: 'assets/icons/user.svg' },
      { name: 'application', icon: 'assets/icons/application.svg' },
      { name: 'unified_workspace', icon: 'assets/icons/unified_workspace.svg' },
      { name: 'dashboard', icon: 'assets/icons/dashboard.svg' },
      { name: 'flow', icon: 'assets/icons/flow.svg' },
      { name: 'photo', icon: 'assets/icons/photo.svg' },
      { name: 'hub', icon: 'assets/icons/hub_outlined.svg' },
      { name: 'spoke', icon: 'assets/icons/spoke.svg' },
      { name: 'podcasts', icon: 'assets/icons/podcasts.svg' },
      { name: 'move', icon: 'assets/icons/move.svg' },
      { name: 'zoom_in', icon: 'assets/icons/zoom_in.svg' },
      { name: 'zoom_out', icon: 'assets/icons/zoom_out.svg' },
      { name: 'ms_signin', icon: 'assets/icons/ms_signin.svg' },
      { name: 'support', icon: 'assets/icons/support.svg' },
      { name: 'support', icon: 'assets/icons/support.svg' },
      { name: 'byop', icon: 'assets/icons/byop.svg' },
      { name: 'hotdesking', icon: 'assets/icons/hotdesking.svg' },
      { name: 'singpass-logo', icon: 'assets/svg/singpass_logo.svg' },
      { name: 'singpass-btn', icon: 'assets/svg/singpass_inline_button.svg' },
      { name: 'group', icon: 'assets/icons/group.svg' },
      { name: 'extension', icon: 'assets/icons/extension.svg' },
      { name: 'email', icon: 'assets/icons/email.svg' },
      { name: 'clock', icon: 'assets/icons/clock.svg' },
      { name: 'pst', icon: 'assets/icons/pst.svg' },
      { name: 'test_hub', icon: 'assets/icons/test_hub.svg' }
    ];

    icons.forEach(x => {
      this.matIconRegistry.addSvgIcon(x.name, this.domSanitizer.bypassSecurityTrustResourceUrl(x.icon));
    });
  }

  private addStripeJs() {
    this.portalConfigQuery.portalConfig$.pipe(takeUntil(this._destroySessionToken$)).subscribe(portalConfig => {
      if (portalConfig.allowTopup) {
        const scriptV2 = document.createElement('script');
        scriptV2.type = 'text/javascript';
        scriptV2.src = 'https://js.stripe.com/v2/';
        document.head.appendChild(scriptV2);
        const scriptV3 = document.createElement('script');
        scriptV3.type = 'text/javascript';
        scriptV3.src = 'https://js.stripe.com/v3/';
        document.head.appendChild(scriptV3);
      }
    });
  }

  private _handleNavigateEvents() {
    const navigationEnd$ = this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd),
      map(e => e as NavigationEnd)
    );

    // once time
    navigationEnd$.pipe(takeUntil(this._destroyNavigationEnd$)).subscribe(event => {
      if (this.activatedRoute.firstChild) {
        if (this.activatedRoute.firstChild.component?.['name'] === MainViewComponent?.name) {
          if (
            this.activatedRoute.firstChild.firstChild &&
            this.activatedRoute.firstChild.firstChild.snapshot.routeConfig.path === '**'
          ) {
            const orgUuid = this.activatedRoute.firstChild.snapshot.params['orgUuid'];
            const view = this.activatedRoute.firstChild.snapshot.params['view'];

            if (!!orgUuid && !!view) {
              const path = this.getPath(event.url, orgUuid, view);
              if (path) {
                this.applicationQuery
                  .selectApplicationbyId(orgUuid, view)
                  .pipe(
                    filter(x => x != null),
                    take(1)
                  )
                  .subscribe(app => {
                    this.applicationService.setMoreQueryForIframe(app, {
                      path: [path]
                    });
                    this._destroyNavigationEnd$.next(true);
                    this._destroyNavigationEnd$.complete();
                  });
              }
            }
          }
        }
      }
      this._destroyNavigationEnd$.next(true);
      this._destroyNavigationEnd$.complete();
    });

    // tracking url of iframe to binding 2 way
    navigationEnd$
      .pipe(
        filter(
          () =>
            !!this.activatedRoute.firstChild &&
            this.activatedRoute.firstChild.component &&
            this.activatedRoute.firstChild.component['name'] === MainViewComponent.name
        ),
        distinctUntilKeyChanged('url'),
        debounceTime(200)
      )
      .subscribe(event => {
        const orgUuid = this.activatedRoute.firstChild.snapshot.params['orgUuid'];
        const view = this.activatedRoute.firstChild.snapshot.params['view'];
        const url = decodeURIComponent(event.url);
        if (!!orgUuid && !!view) {
          const path = this.getPath(url, orgUuid, view);
          if (path) {
            this.applicationQuery
              .selectApplicationbyId(orgUuid, view)
              .pipe(
                filter(x => x != null),
                take(1)
              )
              .subscribe(app => {
                if (app?.lastNavigate != null && app?.lastNavigate !== path) {
                  this.windowService.fireEventAllRegisters(
                    EventMapName.changedNavigateRouter,
                    {
                      path: path
                    },
                    orgUuid
                  );
                }
              });
          }
        }
      });

    navigationEnd$.subscribe(evt => {
      if (evt instanceof NavigationEnd && this.activatedRoute.firstChild) {
        this.appStateService.toggleSidebar(this.activatedRoute.firstChild.snapshot.data['showMainSidebar'] !== false);
      }
      this.updatePageTitle();
    });
  }

  private getPath(path: string, orgUuid: string, view: string) {
    if (path.startsWith(`/${orgUuid}/`)) {
      path = path.substring(`/${orgUuid}/`.length);
    }
    if (path.startsWith(`${view}`)) {
      path = path.substring(`${view}`.length);
    }
    while (path.startsWith('/')) {
      path = path.substring(1);
    }
    if (path.includes(';')) {
      path = path.split(';')[0];
    }
    if (path.includes('?')) {
      path = path.split('?')[0];
    }

    return path;
  }

  private _go2loginPage() {
    if (!this.router.url.includes('auth')) {
      this.router.navigate(['auth'], {
        queryParams: {
          redirectUrl: encodeURIComponent(location.hash.replace('#', ''))
        }
      });
    }
  }

  private getNotificationTxn() {
    const meIdentity = this.sessionQuery?.profile?.uuid;
    this.notificationsService
      .queryNotifications(
        meIdentity,
        <QueryNotificationReq>{
          afterExpiryTime: Date.now(),
          isClicked: false
        },
        new Pageable(1, 10)
      )
      .subscribe(res => {
        if (res?.contacts.length > 0) {
          this.storeContacts(res.contacts);
        }

        const notifications = res.notifications
          ?.filter(n => n.type !== NotificationType.selfAssigned)
          .map(item => this.transformToastData(item));
        notifications.forEach(item => {
          // Show notify browser and sound.
          this.sendNotifyAndSound(item.templateContext?.notification);
          const ref = this.toastService.addToast(item);
          if (ref) {
            this._listNotifyShowed[ref.guid] = ref;
            this.audioPlayerService.play(TypeSound.notify, false);
          }
        });
      });

    this.notificationReceiveProcessor
      .onNotification()
      .pipe(takeUntil(this._destroyWebsocket$))
      .subscribe(noti => {
        logger.info('messageProcessor: ', noti);
        if ([NotificationType.accepted, NotificationType.rejectAssign].includes(noti.type)) {
          if (noti.triggeredByUuid === meIdentity) {
            // dont show noti
            return;
          }
        }

        if (
          (NotificationType.assigningTimeout === noti.type && meIdentity === noti.affectedUuid) ||
          NotificationType.selfAssigned === noti.type
        ) {
          // dont show noti
          return;
        }

        const toast = this.transformToastData(noti);
        if (!noti.isClicked) {
          // Show notify browser and sound.
          this.sendNotifyAndSound(noti);
          if (this._listNotifyShowed[toast.guid]) {
            this.toastService.updateToast(toast);
            this._listNotifyShowed[toast.guid] = toast;
          } else {
            const ref = this.toastService.addToast(toast);
            if (ref) this._listNotifyShowed[ref.guid] = ref;
          }
        } else {
          this.toastService.removeToast(toast.guid);
          this._listNotifyShowed[toast.guid] = undefined;
        }
      });
  }

  private sendNotifyAndSound(notify: Notification) {
    if (notify.isShowNotifyBrowser && this.notifyCache?.get(notify.txnUuid) !== notify.id) {
      const handlePlaySoundAndSaveNotify = (notify: Notification) => {
        this.audioPlayerService.play(TypeSound.notify, false);
        this.notifyCache.set(notify.txnUuid, notify.id);
      };

      const orgUuid = this.sessionQuery.currentOrg.orgUuid;
      !this.windownActiveService.windowActiveStatus &&
      this.browserNotification.permission === Permission.granted &&
      this.browserNotification.isSupported()
        ? this.browserNotification
            .sendNotify(
              'New notification',
              <NotificationOptions>{
                body: notify.displayText,
                icon: this.identityProfileQuery.currentOrg?.photoSrc
              },
              <NotificationRouterLink>{ commands: [orgUuid, 'home', `txn/${notify.txnUuid}`] }
            )
            .subscribe(data => data.event?.type === 'show' && handlePlaySoundAndSaveNotify(notify))
        : handlePlaySoundAndSaveNotify(notify);
    }
  }

  private storeContacts(contacts: Contact[]) {
    if (contacts?.length > 0) {
      this.contactService.updateContacts2Store(contacts);
    }
  }

  private transformToastData(item: Notification) {
    const data = new ToastData(<ToastData>{
      guid: item.id + '',
      time: item.createdAt,
      template: this.txnNotification,
      templateContext: <TxnNotificationModel>{
        classDarkMode: this.themeService.isDarkMode ? 'dark-theme-portal-base' : null,
        notification: item,
        duration: [NotificationType.accepted].includes(item?.type) ? 5000 : -1,
        removeToast: ($event: string) => {
          this.toastService.removeToast($event);
          this._listNotifyShowed[$event] = undefined;
        },
        navigate: ($event: { notification: Notification; isAccepted: boolean }) => {
          logger.info('$event: ', $event);
          // TODO: check license to access UW
          const orgUuid = this.sessionQuery.currentOrg.orgUuid;
          const app = this.applicationQuery.getApplicationbyId(orgUuid, 'home');
          logger.info('app: ', app);
          const path = `txn/${$event.notification.txnUuid}`;
          logger.info('path: ', path);
          if (app?.renderStatus === RenderStatus.loaded) {
            this.router.navigate([orgUuid, 'home', path]);
          } else {
            this.applicationService.setMoreQueryForIframe(app, {
              path: [path]
            });
            this.router.navigate([orgUuid, 'home', path]);
          }
        }
      }
    });

    return data;
  }

  private setFavicon() {
    this.partnerQuery.partner$
      .pipe(
        filter(partner => !!partner?.domainName),
        take(1),
        delay(2000)
      )
      .subscribe(partner => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link['type'] = 'image/x-icon';
        link['rel'] = 'icon';
        link['id'] = 'favicon';
        link['href'] = partner.faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);

        this.favicon = new Favico({
          animation: 'none'
        });
      });
  }
}
