<div *ngIf="isShowNotifyExpiryPass" class="bg-yellow-100 w-full alert-message">
  <div class="msg">
    Your password will expire
    <ng-container *ngIf="passwordDaysBeforeExpiry; else isToday">
      in <strong> {{ passwordDaysBeforeExpiry }} </strong> day(s).
    </ng-container>
    <ng-template #isToday>today.</ng-template>
  </div>
  <button mat-icon-button (click)="isShowNotifyExpiryPass = false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="currentOrg?.isSuperAdmin" class="bg-red-100 w-full leading-9 text-center">Remote support is enabled.</div>

<iframe
  *ngFor="let frame of iframeApps$ | async; trackBy: trackByApp"
  [src]="domain + frame.rightSourcePath | safe: 'resourceUrl'"
  id="{{ frame.id }}"
  class="content-item h-screen"
  [hidden]="frame.id !== activeAppId || frame.rendering || viewNotFound"
  (load)="iframeLoaded(frame)"
></iframe>

<!-- Will have two set of apps, iframe and web element -->
<div class="view-not-found flex justify-center items-center gap-4" *ngIf="viewNotFound">
  <div><mat-icon>warning</mat-icon></div>
  <div>
    <h1>Page not found</h1>
    <div class="text">
      <p class="general">
        <span>Sorry, the page you are looking for does not exist. Please check the URL, otherwise, click </span>
        <a [routerLink]="['/', 'home']">here</a> <span> to browse your home page.</span>
      </p>
    </div>
  </div>
</div>

<b3n-landing-page *ngIf="showLandingPage"></b3n-landing-page>
