import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NoteState, NoteStore } from './note.store';

@Injectable({ providedIn: 'root' })
export class NoteQuery extends QueryEntity<NoteState> {
  constructor(protected override store: NoteStore) {
    super(store);
  }
}
