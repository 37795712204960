import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { Watchers, WatcherUI } from './watchers.model';

export interface WatchersState extends EntityState<Watchers> {}

export type WatcherUIState = EntityState<WatcherUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_watcher', idKey: 'txnUuid' })
export class WatchersStore extends EntityStore<WatchersState> {
  override ui: EntityUIStore<WatcherUIState>;

  constructor() {
    super();

    this.createUIStore({}, { deepFreezeFn: obj => obj, resettable: true }).setInitialEntityState(
      entity =>
        <WatcherUI>{
          loaded: false
        }
    );
  }
}
