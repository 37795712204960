<h1 mat-dialog-title>Enable Two-Factor Authentication</h1>

<div mat-dialog-content>
  <div class="flex flex-col gap-4">
    <div class="secondary-text">Select an option to enable Two-Factor Authentication</div>

    <div
      class="opt flex flex-col gap-2"
      (click)="selectType = TypeTFA.email"
      [class.active]="selectType === TypeTFA.email"
    >
      <div class="flex justify-between">
        <span class="title">Enable With Email</span>
        <mat-icon *ngIf="selectType === TypeTFA.email">check</mat-icon>
      </div>
      <ng-container *ngIf="selectType === TypeTFA.email">
        <mat-divider></mat-divider>
        <div>
          <span class="secondary-text">Verification code will be sent to </span>
          <strong>{{ data.email }}</strong>
        </div>
      </ng-container>
    </div>

    <div
      class="opt flex flex-col gap-2"
      (click)="selectType = TypeTFA.authenticator"
      [class.active]="selectType === TypeTFA.authenticator"
    >
      <div class="flex justify-between">
        <span class="title">Enable With Authenticator App</span>
        <mat-icon *ngIf="selectType === TypeTFA.authenticator">check</mat-icon>
      </div>

      <ng-container *ngIf="selectType === TypeTFA.authenticator">
        <mat-divider></mat-divider>
        <div>
          <span class="secondary-text">Get free verification code with the Authenticator App</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" [disabled]="!selectType" (click)="continue()">Continue</button>
</div>
