import { Injectable } from '@angular/core';
import { HashMap, Store, StoreConfig } from '@datorama/akita';

export function createInitialState(): HashMap<string> {
  return {} as HashMap<string>;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth_self_permission' })
export class SelfPermissionStore extends Store<HashMap<string>> {
  constructor() {
    super(createInitialState());
  }
}
