export enum TimeRangeKey {
  '5m' = '5m',
  '15m' = '15m',
  '30m' = '30m',
  '1h' = '1h',
  '4h' = '4h',
  '12h' = '12h',
  '24h' = '24h',
  '1d' = '1d',
  today = 'today',
  yesterday = 'yesterday',
  lastWeek = 'lastWeek',
  last7days = 'last7days',
  last14days = 'last14days',
  last30days = 'last30days',
  last60days = 'last60days',
  last90days = 'last90days',
  lastMonth = 'lastMonth',
  last6months = 'last6months',
  last1year = 'last1year',
  thisWeek = 'thisWeek',
  thisMonth = 'thisMonth',
  specific_date = 'specific_date',
  custom_date = 'custom_date'
}
