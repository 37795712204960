<div class="h-screen flex flex-col justify-center items-center gap-4">
  <p>
    <mat-icon class="!size-12 !text-[48px] text-[#fbbd08]">warning</mat-icon>
  </p>
  <h1>Access denied</h1>
  <p>
    Sorry, you don't have any permission on this organization. Please check the URL, otherwise, click
    <a href="javascript:;" (click)="showSwitchOrganizationDialog()">here</a>
    to switch to another organization.
  </p>
</div>
