<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    mode="side"
    [opened]="showSidebar"
    class="sidenav"
    [class.dark-theme-portal-base]="isDarkMode$ | async"
    [disableClose]="true"
  >
    <b3n-sidebar></b3n-sidebar>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- // customize notification -->
<ng-template
  #txnNotification
  let-notification="notification"
  let-isPortal="isPortal"
  let-duration="duration"
  let-removeToast="removeToast"
  let-navigate="navigate"
>
  <b3n-txn-notification
    [notification]="notification"
    [duration]="duration"
    [isPortal]="isPortal"
    (removeToast)="removeToast($event)"
    (navigate)="navigate($event)"
  ></b3n-txn-notification>
</ng-template>
