import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseActivity, getInfoActivityReq } from './activities.model';
import { ActivitiesQuery } from './activities.query';
import { ActivitiesStore } from './activities.store';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(
    private http: HttpClient,
    private store: ActivitiesStore,
    private query: ActivitiesQuery
  ) {}

  getActivityDetail(req: getInfoActivityReq) {
    return this.http.get<CaseActivity>(
      `inbox/private/v1/txn/${req.txnUuid}/activities/${req.activityId}?activityType=${req.activityType}`
    );
  }

  addActivityToStore(activity: CaseActivity) {
    this.store.upsert(activity.id, activity);
  }

  deleteActivity(activityId: number) {
    this.store.remove(activityId);
  }
}
