import { ILogObj, Logger } from 'tslog';

/// Please prefer read https://betterstack.com/community/guides/logging/log-levels-explained/ before using log levels
///
const logger: Logger<ILogObj> = new Logger({
  name: 'default',
  type: 'pretty'
});

let dbName = 'logs-database';
const logStoreName = 'logs-store';

let dbPromise: Promise<IDBDatabase> | null = null;
let dbInstance: IDBDatabase | null = null;

// IndexedDB utility functions
function openDatabase(): Promise<IDBDatabase> {
  if (!dbPromise) {
    dbPromise = new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1); // 1 is the version of the database

      // this one only changes when the database version changes
      request.onupgradeneeded = event => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(logStoreName)) {
          db.createObjectStore(logStoreName, { keyPath: '_meta.timestamp' });
        }
      };

      request.onsuccess = event => {
        dbInstance = (event.target as IDBOpenDBRequest).result;

        dbInstance.onversionchange = () => {
          dbInstance?.close();
          dbInstance = null;
        };

        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = event => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }
  return dbPromise;
}

function writelog(log: ILogObj, db: IDBDatabase | null): void {
  try {
    if (db && db.objectStoreNames.contains(logStoreName)) {
      const transaction = db.transaction(logStoreName, 'readwrite');
      const store = transaction.objectStore(logStoreName);
      store.add(log);
    }
  } catch (error) {
    console.error('Failed to save log to IndexedDB', error);
  }
}

// Custom transport function
function indexedDBTransport(logObject: ILogObj) {
  const extendedLogObj = { ...logObject };
  extendedLogObj['_meta'] = extendedLogObj['_meta'] || {};
  extendedLogObj['_meta']['timestamp'] = Date.now();

  if (dbInstance) {
    writelog(extendedLogObj, dbInstance);
  } else {
    openDatabase()
      .then(db => {
        writelog(extendedLogObj, db);
      })
      .catch(error => {
        console.error('Failed to save log to IndexedDB', error);
      });
  }
}

async function cleanupOldLogs() {
  const db = await openDatabase();

  if (db && db.objectStoreNames.contains(logStoreName)) {
    const transaction = db.transaction(logStoreName, 'readwrite');
    const store = transaction.objectStore(logStoreName);
    const now = Date.now();
    const threeDaysAgo = now - 3 * 24 * 60 * 60 * 1000;

    const request = store.openCursor();
    request.onsuccess = event => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
      if (cursor) {
        const logEntry = cursor.value;
        if (logEntry._meta.timestamp < threeDaysAgo) {
          cursor.delete();
        }
        cursor.continue();
      }
    };

    request.onerror = event => {
      logger.error('Error cleaning up old logs:', (event.target as IDBRequest).error);
    };
  }
}

function initializeLogger(application: string, production: boolean = true, storeLogs: boolean = false): void {
  dbName = application + '-logs-database';
  logger.settings.name = application;
  logger.settings.hideLogPositionForProduction = production;
  logger.settings.minLevel = production ? 3 : 0;

  if (production) {
    logger.settings.type = 'hidden';
  }

  if (storeLogs && window.indexedDB) {
    // Attach the custom transport to the logger
    logger.attachTransport(indexedDBTransport);

    cleanupOldLogs();
  }
}

export { initializeLogger, logger };
