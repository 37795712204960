<h1 mat-dialog-title>Update call note</h1>

<div mat-dialog-content>
  <form #form="ngForm" class="!flex !flex-col items-stretch justify-start gap-[16px]">
    <ng-container *ngIf="hasCustomField; else loadDespositionCode">
      <div class="!w-full">
        <div *ngFor="let field of queueConfig.customFields; let i = index">
          <b3n-display-custom-field [detailField]="field"></b3n-display-custom-field>
        </div>
      </div>
    </ng-container>
    <ng-template #loadDespositionCode>
      <mat-form-field *ngIf="queueConfig" class="!w-full">
        <mat-label>Disposition code</mat-label>
        <mat-select placeholder="Disposition code" [(ngModel)]="req.code" name="dispositionCode">
          <mat-option value="">No deposition code</mat-option>
          <mat-option *ngFor="let code of queueConfig.agentWorkflowConfig.codeOptions" [value]="code">{{
            code
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <mat-form-field>
      <textarea matInput placeholder="Note" name="note" [(ngModel)]="req.note" rows="3"></textarea>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-raised-button [disabled]="progressing || form.invalid" color="primary" (click)="progress()">
    <span *ngIf="!progressing">Update</span> <span *ngIf="progressing">Updating...</span>
  </button>
</div>
