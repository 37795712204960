import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove } from '@datorama/akita';
import { tap } from 'rxjs';
import { Watchers } from './watchers.model';
import { WatchersQuery } from './watchers.query';
import { WatchersStore } from './watchers.store';

@Injectable({
  providedIn: 'root'
})
export class WatchersService {
  constructor(
    private http: HttpClient,
    private store: WatchersStore,
    private query: WatchersQuery
  ) {
    console.log(this);
  }

  getWatchersV2(txnUuid: string) {
    return this.http.get<string[]>(`inbox/private/v2/txn/watchers/${txnUuid}`).pipe(
      tap(result => {
        this.store.upsert(txnUuid, { watchers: result });
        this.store.ui.update(txnUuid, {
          loaded: true
        });
      })
    );
  }

  watchCaseV2(txnUuid: string) {
    return this.http.post<string[]>(`inbox/private/v2/txn/watchers/${txnUuid}`, null).pipe(
      tap(watchersUuid => {
        this.store.upsert(txnUuid, {
          watchers: watchersUuid
        });
      })
    );
  }

  unwatchCaseV2(txnUuid: string) {
    return this.http.delete<string[]>(`inbox/private/v2/txn/watchers/${txnUuid}`).pipe(
      tap(watchersUuid => {
        this.store.upsert(txnUuid, {
          watchers: watchersUuid
        });
      })
    );
  }

  addWatcher(txnUuid: string, identityUuids: string[]) {
    const entity = this.query.getEntity(txnUuid);
    if (entity) {
      this.store.update(txnUuid, ({ watchers }) => ({
        watchers: arrayAdd(watchers, identityUuids)
      }));
    } else {
      const watcher: Watchers = {
        txnUuid: txnUuid,
        watchers: identityUuids
      } as Watchers;
      this.store.upsertMany([watcher]);
    }
  }

  removeWatcher(txnUuid: string, identityUuids: string[]) {
    this.store.update(txnUuid, ({ watchers }) => ({
      watchers: arrayRemove(watchers, identityUuids)
    }));
  }
}
