<mat-toolbar-row class="action-bar flex flex-1 items-center justify-end gap-[8px]">
  <mat-form-field class="w-155px no-validator">
    <mat-label>Auto refresh</mat-label>
    <mat-select
      [(ngModel)]="settings.workspaceAgents.autoRefreshTime"
      [compareWith]="compareCode"
      (selectionChange)="onFilterChanged()"
      placeholder="Auto refresh"
    >
      <mat-option *ngFor="let refresh of intervalTimes" [value]="refresh.key"> {{ refresh.value }} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field (click)="rangePicker.open()" class="no-validator">
    <mat-label>Start date - End date</mat-label>
    <mat-date-range-input [min]="minDate" [max]="maxDate" [rangePicker]="rangePicker">
      <input matStartDate [(ngModel)]="dateRange.start" placeholder="Start date" readonly />
      <input matEndDate [(ngModel)]="dateRange.end" placeholder="End date" readonly />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Cancel</button>
        <button mat-flat-button color="primary" matDateRangePickerApply (click)="onFilterChanged()">Apply</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>

  <mat-form-field *ngIf="queues" class="w-155px no-validator">
    <mat-label>Filter by queue</mat-label>
    <mat-select
      [(ngModel)]="settings.workspaceAgents.queueFiltering"
      (selectionChange)="onFilterChanged()"
      placeholder="Filter by queue"
    >
      <mat-option [value]="ALL_QUEUES">All queues</mat-option>
      <mat-option *ngFor="let queueItem of queues" [value]="queueItem.uuid"> {{ queueItem.label }} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-155px no-validator">
    <mat-label>Sort by</mat-label>
    <mat-select
      [(ngModel)]="settings.workspaceAgents.sortBy"
      [compareWith]="compareCode"
      (selectionChange)="onFilterChanged()"
      placeholder="Sort by"
    >
      <mat-option *ngFor="let sort of sortAgentBy" [value]="sort.key"> {{ sort.value }} </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-stroked-button class="export" (click)="export()">Export</button>

  <button mat-icon-button [loading]="fetching" (click)="onFilterChanged()" matTooltip="Refresh">
    <mat-icon>autorenew</mat-icon>
  </button>
</mat-toolbar-row>
