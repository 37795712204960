import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import { B3Sentry } from '../utils/sentry/b3-sentry';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    console.error('error: ', error);

    if (B3Sentry.enabledSentryLog) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 0) {
          console.error('An error occurred:', error.error);
        } else if (error.status === 401) {
          console.error('An error occurred:', error.error);
        } else if (error.status === 403) {
          console.error('An error occurred:', error.error);
        } else if (error.status === 404) {
          console.error('An error occurred:', error.error);
        } else if (error.status === 500) {
          console.error('An error occurred:', error.error);
        } else if (error?.status && error?.status >= 400) {
          console.error('An error occurred:', error.error);
        } else {
          captureException(error);
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
}
