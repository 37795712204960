<h1 mat-dialog-title class="title">Busy remark</h1>

<mat-dialog-content>
  <div class="form-container">
    <mat-form-field>
      <mat-select
        [(ngModel)]="reason"
        placeholder="Choose busy remark"
        name="busyReason"
        #busyReason="ngModel"
        required
      >
        <mat-option *ngFor="let reason of busyRemarks$ | async" [value]="reason"> {{ reason }} </mat-option>
      </mat-select>
      <mat-error class="sms-warning" *ngIf="busyReason.invalid && (busyReason.dirty || busyReason.touched)"
        >Busy remark is required.
      </mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Close</button>
  <button mat-flat-button color="primary" (click)="submit()" [disabled]="busyReason.invalid">Update</button>
</div>
