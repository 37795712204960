import { Injectable } from '@angular/core';
import { PER_PAGE_TXN } from '@b3networks/shared/common';
import { ActiveState, EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { ChannelStateV3, ContactUI } from './contact-ui.model';
import { Contact, ContactFilterBy, ContactsFilterState } from './contact.model';

export interface ContactState extends EntityState<Contact>, ActiveState {
  filter: ContactsFilterState;
}

export interface ContactUIState extends EntityState<ContactUI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'contact_contact', idKey: 'uuid' })
export class ContactStore extends EntityStore<ContactState> {
  override ui: EntityUIStore<ContactUIState>;
  constructor() {
    super({
      filter: { type: ContactFilterBy.ORGANIZATION, keyword: '' }
    });
    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(<ContactUIState>{
      loaded: false,
      viewingOlderMessage: false,
      txnFilterV4: <ChannelStateV3>{
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: []
    });
  }

  updateUiState(filter: Partial<ContactsFilterState>) {
    this.update(state => ({
      filter: {
        ...state.filter,
        ...filter
      }
    }));
  }
}
