<h1 mat-dialog-title>Enable With Email</h1>

<div mat-dialog-content>
  <div class="flex flex-col gap-4">
    <p>Verification code will be sent via email on the next login</p>

    <div class="flex justify-between items-center">
      <div>
        <span>Current email: </span><strong>{{ data.email }}</strong>
      </div>
      <button
        mat-stroked-button
        color="primary"
        [loading]="sendOtpButtonOptions.loading"
        (click)="sendOtpCodeToEmail()"
      >
        {{ sendOtpButtonOptions.text }}
      </button>
    </div>

    <mat-form-field hideRequiredMaker="true">
      <mat-label>Verification code</mat-label>
      <span matTextPrefix *ngIf="sanitizedCode">{{ sanitizedCode }} -&nbsp;</span>
      <input matInput [formControl]="codeCtrl" (keyup.enter)="continue()" required />
      <mat-error *ngIf="codeCtrl.invalid">Please enter the verification code</mat-error>
      <mat-error *ngIf="errorStr">{{ errorStr }}</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    [loading]="loading"
    [disabled]="sanitizedCode && codeCtrl.invalid"
    (click)="continue()"
  >
    Verify
  </button>
</div>
