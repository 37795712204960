import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { X_B3_HEADER } from '@b3networks/shared/common';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PermissionGroup } from '../iam/iam-group.model';
import { IAMGrantedPermission } from '../iam/iam.model';
import { Team } from '../team/team.model';
import { IdentityProfile, ProfileOrg } from './identity-profile.model';
import { IdentityProfileStore, ProfileState } from './identity-profile.store';

/**
 * Portal app using SessionService instead
 */
@Injectable({ providedIn: 'root' })
export class IdentityProfileService {
  constructor(
    private http: HttpClient,
    private store: IdentityProfileStore
  ) {}

  /**
   * Get current profile
   * This API should call one time per app
   *
   * reuseProfile?: ProfileOrg; // for portal use case, when app already loaded org and wanna to reuse. This also fix for duplicated login event on auth
   */
  getProfile(addon?: {
    fetchPermission?: boolean;
    fetchPG?: boolean;
    reuseProfile?: IdentityProfile;
  }): Observable<IdentityProfile> {
    const streams$: Observable<any>[] = [
      addon?.reuseProfile
        ? of(addon.reuseProfile)
        : this.http
            .get<IdentityProfile>(`auth/private/v1/organizations/members`, {
              params: { page: '0', size: '1' }
            })
            .pipe(
              catchError(() => of(null)),
              map(res => new IdentityProfile(res))
            )
    ];

    if (addon) {
      if (addon.fetchPermission) {
        streams$.push(
          this.http.get<IAMGrantedPermission[]>(`auth/private/v1/iam`).pipe(
            catchError(() => of([])),
            map(list => list.map(l => new IAMGrantedPermission(l)))
          )
        );
      }
      if (addon.fetchPG) {
        streams$.push(
          this.http.get<PermissionGroup[]>(`auth/private/v1/iam/group/self`).pipe(
            catchError(() => of([])),
            map(x => x || [])
          )
        );
      }
    }

    return (streams$.length === 0 ? streams$[0] : forkJoin(streams$)).pipe(
      tap(data => {
        const profile = data[0];
        const permisisons = addon?.fetchPermission ? data[1] : null;
        const pg =
          addon?.fetchPermission && addon?.fetchPG
            ? data[2]
            : addon?.fetchPG && !addon?.fetchPermission
              ? data[1]
              : null;

        const state: ProfileState = {
          profile: profile,
          currentOrg: profile.organizations?.length === 1 ? profile.organizations[0] : null
        };
        if (permisisons) {
          state.assignedPermissions = permisisons;
        }
        if (pg) {
          state.assignedPermissionGroups = pg;
        }

        this.store.update(state);
      }),
      map(([profile]) => profile)
    );
  }

  /** Get belongs to teams */
  getTeams(identityUuid: string | null = null) {
    const params = identityUuid && { identityUuid };
    return this.http.get<Team[]>(`/auth/private/v1/organizations/teams`, { params }).pipe(
      map(res => res.filter(t => t.active)),
      tap(res => {
        this.store.update({ teams: res });
      })
    );
  }

  getAllBelongOrgs(): Observable<ProfileOrg[]> {
    const headers = new HttpHeaders().set(X_B3_HEADER.orgUuid, '');
    return this.http
      .get<IdentityProfile>(`auth/private/v1/organizations/members`, {
        headers: headers,
        params: { page: '0', size: '1000' }
      })
      .pipe(map(res => new IdentityProfile(res).organizations));
  }
}
