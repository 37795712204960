import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

export interface LanguageState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'portal_language' })
export class LanguageStore extends EntityStore<LanguageState> {
  constructor() {
    super();
  }
}
