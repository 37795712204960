import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '@b3networks/api/chat';
import { API_RUN_AT_BACKDOOR } from '@b3networks/api/file';
import { CustomEncoder } from '@b3networks/shared/auth';
import {
  CAPACITY_LIMIT_ERROR,
  CHAT_PUBLIC_PREFIX,
  DomainUtilsService,
  ERROR_PERMISSION,
  INJECT_CLIENT_ID,
  UNEXPECTED_ERROR,
  X_B3_HEADER,
  generateRandomString,
  isLocalhost,
  logger
} from '@b3networks/shared/common';
import { Observable, from, lastValueFrom, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SessionQuery } from '../service/session/session.query';
import { SessionService } from '../service/session/session.service';

const PRIVATE_FILE_PREFIX = '/file/private';
const PUBLIC_FILE_PREFIX = '/file/public';

interface ErrorCustom {
  message: string;
  status?: number;
  error?: string;
  code?: string;
}

@Injectable({ providedIn: 'root' })
export class PortalAuthInterceptor implements HttpInterceptor {
  apiUrl: string = this.domainService.apiUrl;

  constructor(
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private domainService: DomainUtilsService,
    private chatService: ChatService,
    private router: Router,
    @Optional() @Inject(INJECT_CLIENT_ID) private _clientID
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const startTime = Date.now();
    const corrId = generateRandomString(10);

    const realReq = req.clone({
      url: this.buildUrl(req.url),
      headers: this.buildHeaders(req.headers, { corrId, startTime }),
      params: req.params ? this.buildParams(req.params) : req.params,
      withCredentials: isLocalhost() ? true : false // development need this to post cookie to outside
    });

    let result;
    if (
      (req.method === 'POST' || req.method === 'PUT') &&
      req.url != 'auth/private/v1/xsrf' &&
      req.headers.get(X_B3_HEADER.validateXSRFToken) === 'true'
    ) {
      console.log(`${new Date()}: issue xsrf token...`);
      result = from(this._generateXSRFToken(req, next)).pipe(
        switchMap(() => {
          console.log(`${new Date()}: continue to call api ${realReq.url}`);
          return next.handle(realReq);
        })
      );
    } else {
      result = next.handle(realReq);
    }

    return result.pipe(
      catchError((err: HttpErrorResponse) => {
        const errCustom = this._transformErrorFormat(err);
        const isChatPublic = this._isChatRequest(req.url);
        if (
          errCustom.status === 401 &&
          !req.url.includes('/auth/private/v1/sessiontokens') &&
          !req.url.includes('/auth/private/v2/sessiontokens') &&
          !isChatPublic
        ) {
          from(this._handle401Error(req, next, err));
        }

        // blocked ipaddress of admin portal
        if (errCustom.status === 400 && errCustom?.['sec'] === 203) {
          from(this._handle400Error(req, next, errCustom));
        }

        return throwError(() => err);
      }),
      tap({
        complete: () => {
          const endTime = Date.now();
          const duration = endTime - startTime;

          if (!req.url.includes('chat_public/timestamp') || duration > 500) {
            logger.info(`Request to ${req.method}:${req.url} with correlationId ${corrId} took ${duration}ms`);
          }
          // Optionally, you can add the duration to the headers or log it
        },
        error: (err: HttpErrorResponse) => {
          const endTime = Date.now();
          const duration = endTime - startTime;

          logger.warn(
            `Request to ${req.method}:${req.url} with correlationId ${corrId} failed after ${duration}ms with statusCode ${err.status} and error ${err.error.message}`
          );
        }
      })
    );
  }

  private _transformErrorFormat(err: HttpErrorResponse) {
    let errCustom: ErrorCustom = err?.error ? err?.error : err;
    if (typeof errCustom === 'string') {
      errCustom = Object.assign({}, <ErrorCustom>{
        message: UNEXPECTED_ERROR.message,
        error: err.error,
        code: UNEXPECTED_ERROR.code,
        status: err.status
      });
    } else {
      errCustom = Object.assign(errCustom, <ErrorCustom>{
        status: err.status
      });
    }

    const e = err ? err?.error : err;
    if (err.status === 401 && !e?.message) {
      errCustom = Object.assign(errCustom, <ErrorCustom>{
        message: ERROR_PERMISSION.message,
        code: ERROR_PERMISSION.code,
        status: err.status
      });
    } else if (!e?.message) {
      errCustom = Object.assign(errCustom, <ErrorCustom>{
        message: UNEXPECTED_ERROR.message,
        code: UNEXPECTED_ERROR.code,
        status: err.status
      });
    }

    // Jira: B3-12800 - Update Error Message for Capacity Limit
    if (errCustom?.code === CAPACITY_LIMIT_ERROR.code) {
      errCustom = Object.assign(errCustom, {
        message: CAPACITY_LIMIT_ERROR.message
      });
    }

    return errCustom;
  }

  private async _handle401Error(request: HttpRequest<unknown>, next: HttpHandler, err: HttpErrorResponse) {
    logger.info(`Handle 401 error when calling ${request.method}:${request.url}. Trying to refresh session...`);

    await this.sessionService.refreshSession('handle401Error');

    const error = err?.error ? err.error : err;

    return throwError(() => error);

    // const result = await this.sessionService.refreshSession(`_handle401Error:${request.method}:${request.url}`);
    // if (result) {
    //   const tempReq = request.clone({
    //     url: this.buildUrl(request.url),
    //     headers: this.buildHeaders(request.headers),
    //     params: request.params ? this.buildParams(request.params) : request.params,
    //     withCredentials: isLocalhost() ? true : false // development need this to post cookie to outside
    //   });
    //   return lastValueFrom(next.handle(tempReq));
    // } else {
    //   // redirect to login page
    //   if (!this.router.url.includes('auth')) {
    //     const redirectURI = location.hash.replace('#', '');
    //     console.log('redirectURI', redirectURI);

    //     this.router.navigate(['auth'], {
    //       queryParams: {
    //         redirectUrl: encodeURIComponent(redirectURI)
    //       }
    //     });
    //   }

    //   const error = err?.error ? err.error : err;

    //   if (B3Sentry.enabledSentryLog) {
    //     captureMessage(error);
    //   }
    //   return throwError(() => error);
    // }
  }

  private async _handle400Error(request: HttpRequest<unknown>, next: HttpHandler, err: ErrorCustom) {
    // redirect to access denied page
    if (!this.router.url.includes('access-denied')) {
      this.router.navigate(['access-denied']);
    }

    const error = err?.error ? err.error : err;
    return throwError(() => error);
  }

  private buildUrl(url: string) {
    if (this.validUrl(url)) {
      return url;
    }
    if (url.startsWith('assets')) {
      return url;
    }

    if (url.indexOf('/') !== 0) {
      url = '/' + url;
    }

    if (!isLocalhost()) {
      const hasIgnoreZuul = API_RUN_AT_BACKDOOR.some(api => url.startsWith(api));
      if (hasIgnoreZuul) {
        if (url.startsWith(PRIVATE_FILE_PREFIX)) {
          url = url.slice(PRIVATE_FILE_PREFIX.length);
        } else if (url.startsWith(PUBLIC_FILE_PREFIX)) {
          url = url.slice(PUBLIC_FILE_PREFIX.length);
        }

        return this.domainService.storageBackdoorAPIUrl + (url ? url : '');
      }
    }

    if (this._isChatRequest(url) && this.chatService.session != null) {
      const session = this.chatService.session;
      let apiAddress = session.addr;

      if (!isLocalhost()) {
        apiAddress = `${this.domainService.getPortalDomain()}/_${session.chatNode}`;
      }

      if (url.includes(CHAT_PUBLIC_PREFIX)) {
        url = url.substring(1 + CHAT_PUBLIC_PREFIX.length, url.length);
        url = `https://${apiAddress}/public/user/${session.chatUser}${url}`;
      } else {
        url = `https://${apiAddress}${url}`;
      }
    } else {
      // normal request
      url = this.apiUrl + (url ? url : '');
    }

    return url;
  }

  /**
   * Temporary fix for Angular Team
   * https://github.com/angular/angular/issues/18261
   * @param params
   */
  private buildParams(params: HttpParams) {
    let overridedParams = new HttpParams({ encoder: new CustomEncoder() });
    if (params) {
      params.keys().forEach(key => {
        overridedParams = overridedParams.set(key, params.get(key));
      });
    }
    return overridedParams;
  }

  private validUrl(url: string) {
    return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(url);
  }

  private buildHeaders(headers: HttpHeaders, addonData?: { corrId?: string; startTime?: number }) {
    headers = headers || new HttpHeaders();

    if (!headers.has(X_B3_HEADER.sessionToken)) {
      const fallbackSessionToken = this.sessionQuery.getValue().fallbackSessionToken;
      if (fallbackSessionToken) {
        headers = headers.set(X_B3_HEADER.sessionToken, fallbackSessionToken);
      }
    }
    if (!headers.has(X_B3_HEADER.orgUuid)) {
      const currentOrgUuid = this.sessionQuery.currentOrg?.orgUuid;
      if (currentOrgUuid && currentOrgUuid !== 'company') {
        headers = headers.set(X_B3_HEADER.orgUuid, currentOrgUuid);
      }
    }
    if (this.chatService.session != null) {
      const session = this.chatService.session;
      headers = headers.set('Namespace', session.ns).set('Token', `${session.chatUser}:${session.token}`);
    }

    if (this._clientID) {
      headers = headers.set(X_B3_HEADER.clientAppId, this._clientID);
    }

    if (!headers.has(X_B3_HEADER.correlationId)) {
      const corrId = addonData?.corrId || generateRandomString(10);
      headers = headers.set(X_B3_HEADER.correlationId, corrId);
    }

    if (!headers.has(X_B3_HEADER.clientTime)) {
      const startAt = addonData?.startTime || Date.now();
      headers = headers.set(X_B3_HEADER.clientTime, startAt.toString()); // for calculating duration
    }

    return headers;
  }

  private _isChatRequest(url: string) {
    return (
      url.includes(CHAT_PUBLIC_PREFIX) ||
      url.includes('public/_tc') ||
      url.includes('public/v2/_tc') ||
      url.includes('public/v2/user')
    );
  }

  private async _generateXSRFToken(request: HttpRequest<unknown>, next: HttpHandler) {
    return await lastValueFrom(this._generateXSRFTokenAsync(request, next));
  }

  private _generateXSRFTokenAsync(request: HttpRequest<unknown>, next: HttpHandler) {
    let uri = request.method + ':';
    uri += request.url.startsWith('/') ? request.url : '/' + request.url;
    const generateXSRFTokenReq = new HttpRequest(
      'POST',
      this.buildUrl('auth/private/v1/xsrf'),
      { uri: uri },
      { headers: this.buildHeaders(new HttpHeaders()), withCredentials: isLocalhost() ? true : false }
    );

    return next.handle(generateXSRFTokenReq).pipe(
      catchError(() => {
        return null;
      })
    );
  }
}
