import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AcronymsCapitalizeCasePipe } from '@b3networks/shared/common';

export interface ConfirmDialogInput {
  title?: string;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
  color: string;
  hideCancel?: boolean;
  textConfirm?: string;
  placeholderTextConfirm?: string;
  widthConfirm?: string;
  hideConfirm?: boolean;
  isReturnValueInput?: boolean;
  hasTextarea?: boolean;
  textAreaLabel?: string;
}

export interface ConfirmResponse {
  isConfirm: boolean;
  value: string;
}

@Component({
  selector: 'b3n-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    AcronymsCapitalizeCasePipe
  ]
})
export class ConfirmDialogComponent implements OnInit {
  inputValue: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInput
  ) {}

  ngOnInit() {}

  confirm() {
    this.data.isReturnValueInput
      ? this.dialogRef.close({ isConfirm: true, value: this.inputValue } as ConfirmResponse)
      : this.dialogRef.close(true);
  }
}
