import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FormIntegrationSCState, FormIntegrationSCStore } from './form-integration.store';

@Injectable({ providedIn: 'root' })
export class FormIntegrationSCQuery extends QueryEntity<FormIntegrationSCState> {
  constructor(protected override store: FormIntegrationSCStore) {
    super(store);
  }

  getFormIntegration(templateUuid: string) {
    return this.getEntity(templateUuid);
  }

  getFormIntegrationByInbox(templateUuid: string) {
    return this.getEntity(templateUuid);
  }

  getFormIntegrationByInboxUuid(inboxUuid: string) {
    return this.getValue()?.inboxesForm?.[inboxUuid];
  }
}
