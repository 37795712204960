import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import { TxnChannel } from '../txn/txn.model';
import { NotificationsState, NotificationsStore } from './notifications.store';

@Injectable({ providedIn: 'root' })
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  constructor(protected override store: NotificationsStore) {
    super(store);
  }

  selectLatestNotificationCall() {
    return this.selectAll({
      filterBy: entity => entity.channel === TxnChannel.call,
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    }).pipe(map(x => x?.[0]));
  }
}
