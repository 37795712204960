<div class="announcement__header">
  <h2 class="m-0">Announcements</h2>
  <button mat-flat-button color="primary" (click)="storeAnnouncement()" [disabled]="loading">Create</button>
</div>

<div *ngIf="!announcements.length && !loading; else showList" class="secondary-text w-full flex justify-center">
  No announcements
</div>
<ng-template #showList>
  <mat-card>
    <mat-card-content class="announcement-card-content">
      <section class="announcement__wrapper" *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </section>
      <ng-container *ngIf="!loading">
        <section
          class="announcement__wrapper"
          *ngFor="let announcement of announcements"
          (click)="storeAnnouncement(announcement)"
        >
          <mat-divider vertical color="primary"></mat-divider>
          <div class="announcement">
            <p class="info">{{ announcement.info }}</p>
            <h2 class="title">{{ announcement.title }}</h2>
            <p class="content">{{ announcement.content }}</p>
          </div>
        </section>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-template>
