export interface PortalData {
  orgBackground?: string;
}

export class PortalDomain {
  domainName: string;
  portalTitle: string;
  supportEmail: string;
  salesEmail: string;
  loginHeaderBackground: string;
  loginButtonBackground: string;
  logoUrl: string | ArrayBuffer;
  faviconUrl: string | ArrayBuffer;
  senderEmail: string;

  constructor(value?: Object) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }
}
