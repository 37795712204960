<ng-container *ngIf="filter">
  <mat-toolbar-row class="title-bar flex items-center justify-start gap-[8px]">
    <mat-form-field *ngIf="filter.callType !== 'autodialer'" class="no-validator">
      <mat-label>Call type</mat-label>
      <mat-select
        (selectionChange)="onFilterChanged()"
        [(ngModel)]="filter.callType"
        [compareWith]="compareFn"
        placeholder="Call type"
      >
        <mat-option *ngFor="let callType of callTypes" [value]="callType.key">{{ callType.value }}</mat-option>
      </mat-select>
    </mat-form-field>

    <span class="fill-remaining-space"></span>

    <div class="advanced-search-trigger" (click)="isAdvancedSearch = !isAdvancedSearch" mat-icon-button>
      <span> Advanced search </span>

      <i class="material-icons" *ngIf="isAdvancedSearch"> keyboard_arrow_down </i>

      <i class="material-icons" *ngIf="!isAdvancedSearch"> keyboard_arrow_up </i>
    </div>

    <mat-form-field class="no-validator">
      <mat-label>Filter date</mat-label>
      <mat-select
        (selectionChange)="onFilterChanged()"
        [(ngModel)]="filter.timeRange"
        [compareWith]="compareFn"
        placeholder="Filter date"
      >
        <mat-option *ngFor="let timeRange of timeRanges" [value]="timeRange.key">{{ timeRange.value }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field (click)="selectedStartDate.open()" *ngIf="filter.timeRange == TimeRange.specific_date">
      <input
        (dateChange)="startDateChanged($event)"
        [(ngModel)]="filter.startDate"
        [matDatepicker]="selectedStartDate"
        [max]="maxStart"
        [min]="minStart"
        matInput
        placeholder="Start date"
        readonly
      />

      <mat-datepicker-toggle [for]="selectedStartDate" matSuffix></mat-datepicker-toggle>

      <mat-datepicker #selectedStartDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      class="no-validator"
      (click)="selectedEndDate.open()"
      *ngIf="filter.timeRange == TimeRange.specific_date"
    >
      <input
        (dateChange)="endDateChanged($event)"
        [(ngModel)]="filter.endDate"
        [matDatepicker]="selectedEndDate"
        [max]="maxEnd"
        [min]="minEnd"
        matInput
        placeholder="End date"
        readonly
      />

      <mat-datepicker-toggle [for]="selectedEndDate" matSuffix></mat-datepicker-toggle>

      <mat-datepicker #selectedEndDate></mat-datepicker>
    </mat-form-field>

    <button (click)="onExport()" class="export" mat-stroked-button matTooltip="Export">Export</button>

    <button (click)="onFilterChanged()" mat-icon-button matTooltip="Refresh"><mat-icon>autorenew</mat-icon></button>
  </mat-toolbar-row>

  <mat-accordion>
    <mat-expansion-panel [expanded]="isAdvancedSearch" class="mat-elevation-z0">
      <mat-toolbar-row class="title-bar-expension flex items-center justify-end gap-[8px]">
        <mat-form-field class="no-validator">
          <mat-label>Filter by</mat-label>
          <mat-select
            [(ngModel)]="filter.filterBy"
            (selectionChange)="clearAdvancedFilter()"
            [compareWith]="compareFn"
            placeholder="Filter by"
          >
            <mat-option [value]="'number'">Number</mat-option>

            <mat-option [value]="'txnUuid'">Txn. Uuid</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="filter.filterBy === 'number'" class="no-validator">
          <input matInput placeholder="From number" [(ngModel)]="filter.fromNumber" (keyup.enter)="onFilterChanged()" />

          <mat-icon *ngIf="filter.fromNumber" (click)="filter.fromNumber = ''" matSuffix>clear</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="filter.filterBy === 'number'" class="no-validator">
          <input matInput placeholder="To number" [(ngModel)]="filter.toNumber" (keyup.enter)="onFilterChanged()" />

          <mat-icon *ngIf="filter.toNumber" (click)="filter.toNumber = ''" matSuffix>clear</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="filter.filterBy === 'txnUuid'" class="no-validator">
          <mat-label>Transaction Uuid</mat-label>
          <input matInput [(ngModel)]="filter.txnUuid" (keyup.enter)="onFilterChanged()" />

          <mat-icon *ngIf="filter.txnUuid" (click)="filter.txnUuid = ''" matSuffix>clear</mat-icon>
        </mat-form-field>

        <button (click)="onFilterChanged()" class="export" mat-stroked-button>Search</button>
      </mat-toolbar-row>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
