import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FormFieldSCConfig, InboxesForm } from './form-integration.model';

export interface FormIntegrationSCState extends EntityState<FormFieldSCConfig> {
  inboxesForm: InboxesForm;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-integration-sc', idKey: 'templateUuid' })
export class FormIntegrationSCStore extends EntityStore<FormIntegrationSCState> {
  constructor() {
    super({ inboxesForm: {} });
  }

  updateInboxesForm(inboxUuid: string, formIntegration: FormFieldSCConfig) {
    this.update(state => {
      const inboxesForm = structuredClone(state.inboxesForm);
      const dataInboxesForm = { ...inboxesForm, [inboxUuid]: formIntegration };
      return { ...state, inboxesForm: dataInboxesForm };
    });
  }
}
