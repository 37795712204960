import { CaseActivity } from '@b3networks/api/inbox';
import { ConversationGroup, EmailMessageGeneral, WaTemplateMessageData } from '@b3networks/api/workspace';
import {
  InfoMarkdown,
  ROBIN_BOT_IDENTITY,
  SYSTEM_IDENTITY,
  decodeUserId,
  getBit,
  setBit
} from '@b3networks/shared/common';
import { format } from 'date-fns';
import { ConvoType, GroupType, MsgType, PersistentFlag, SystemType, TransientFlag, UserType } from '../enums.model';
import { ChannelHyperspace } from './../channel-hyperspace/model/channel-hyperspace.model';
import { Channel } from './../channel/model/channel.model';
import { ChatTopic } from './../chat-session/chat-session.model';
import { getTimeFromChatServer } from './../time/time.service';
import { AttachmentMessageData, AttachmentMessageDataV2 } from './chat-message-attachment.model';
import { PreChatSurvey, PreviewMessageData, ResponseTxn } from './chat-message-data.model';
import { ExtraData, LinkedMessages } from './chat-message-extra-data.model';
import { IMessBodyData } from './chat-message-imess.model';
import { InteractiveMessageData } from './chat-message-interactive.model';
import { SystemMessageData } from './chat-message-system.model';
import { TxnMessageData, TxnMoveInboxData } from './chat-message-txn.model';
import {
  AclActionMessageUI,
  BotType,
  BuildMessageUI,
  ChatMessageAttachmentUI,
  PreviewMessageUI,
  StateMessageUI,
  UserStateUI,
  WebhookUI
} from './chat-messsage-ui.model';
import { MSG_TYPE_BUILDER, buildMarkdownMessage } from './parser/message-type-parser.model';

export class ChatMessageBase {
  id: string; //message id return from chat server
  ts: number; // timestamp from server
  client_ts: number; // client timestamp
  convo: string; // conversation id (sub_conversation)
  hs: string; // hyperspaceId
  ns: string; // namespace hyperspace
  user: string; // chat userUuid
  metadata: Metadata; // edited flag
  extraData: ExtraData; // link message

  ut: UserType; // user type (agent, team_member, customer)
  ct: ConvoType; // conversation type (directchat, groupchat, customerchat)
  mt: MsgType; // message type (attachment, system, message, prechatsurvey)

  st: SystemType; // 'seen' this is use for notify that user already read this conversation
  body: MessageBody = new MessageBody();
  err: string;
  tf = 0; // transition flag readmore at https://b3networks.atlassian.net/browse/CHAT-124
  topics?: ChatTopic[]; // want to send msg for websockets are subscribed this topics
  pf: string | number; // persistent flag: on history + live msg

  constructor(obj?: Partial<ChatMessageBase>) {
    this.client_ts = getTimeFromChatServer();
    this.ts = this.client_ts;
    if (obj) {
      Object.assign(this, obj);

      // support transfer lowercase ct
      if (['DM', 'dm', 'direct'].includes(this.ct)) {
        this.ct = ConvoType.direct;
      } else if (['group', 'gc', 'GC'].includes(this.ct)) {
        this.ct = ConvoType.groupchat;
      } else if (['thread', 'THREAD'].includes(this.ct)) {
        this.ct = ConvoType.THREAD;
      }

      if (obj['body']) {
        this.body = new MessageBody(this.body);
        // support transfer lowercase ct
        if (this.ct === <ConvoType>'DM') {
          this.ct = ConvoType.direct;
        } else if (this.ct === <ConvoType>'GC') {
          this.ct = ConvoType.groupchat;
        } else if (['cs', 'CS', 'LIVECHAT', 'livechat'].includes(this.ct)) {
          // `CS` is livechat in old version
          this.ct = ConvoType.LIVECHAT;
        } else if (['internal_space', 'INTERNAL_SPACE'].includes(this.ct)) {
          this.ct = ConvoType.INTERNAL_SPACE;
        }

        // mapping ut
        if (['cust', 'CUST', 'CUSTOMER'].includes(this.ut)) {
          this.ut = UserType.Customer;
        }
      }

      if (obj.metadata) this.metadata = new Metadata(obj.metadata);
      if (obj?.extraData) {
        this.extraData = new ExtraData(obj.extraData);
      } else {
        this.extraData = new ExtraData({ linkedMessages: <LinkedMessages>{ snapshots: <ChatMessage[]>[] } });
      }
      if (this.ts) this.ts = +this.ts;
      if (this.client_ts) this.client_ts = +this.client_ts;
    }
  }

  // for id to hash, some action message don't have id
  get clientId(): string {
    return this.id || this.unixTsAndUser;
  }

  get unixTsAndUser(): string {
    return `${this.client_ts}_${this.user}`;
  }

  get channelId() {
    return this.convo;
  }

  // thread was created by this message
  get hasThread() {
    return !!this.metadata?.threadId;
  }

  get myThreadId() {
    return this.metadata?.threadId;
  }

  get isThread() {
    return this.ct === ConvoType.THREAD;
  }

  get hasMention(): boolean {
    return getBit(+this.tf, TransientFlag.IsMentioned);
  }

  get IsNotified(): boolean {
    return getBit(+this.tf, TransientFlag.IsNotified);
  }

  get isNoStore(): boolean {
    return getBit(+this.tf, TransientFlag.IsNoStored);
  }

  get isNoCountUnread(): boolean {
    return getBit(+this.tf, TransientFlag.isNoCountUnread);
  }

  get isSubstring(): boolean {
    return getBit(+this.pf, PersistentFlag.IsSubstring);
  }

  get isCountUnread() {
    return !this.isNoCountUnread;
  }

  get isStore() {
    return !this.isNoStore;
  }

  get isFromChannel(): boolean {
    // TODO: remove direct&group type
    return (
      [ConvoType.groupchat, ConvoType.direct, 'direct', 'group', ConvoType.THREAD, ConvoType.personal].indexOf(
        this.ct
      ) > -1
    );
  }

  get indexMessageBookmark() {
    return this.extraData?.linkedMessages?.indexMessageBookmark;
  }

  get messageBookmark() {
    return this.extraData?.linkedMessages?.messageBookmark;
  }

  get indexMessageReply() {
    return this.extraData?.linkedMessages?.indexMessageReply;
  }

  get hasMessageReply() {
    return this.extraData?.linkedMessages?.replyTo;
  }

  get messageReply() {
    return this.extraData?.linkedMessages?.messageReply;
  }

  get groupType() {
    return this.ct === ConvoType.LIVECHAT
      ? GroupType.LIVECHAT
      : this.ct === ConvoType.INTERNAL_SPACE
        ? GroupType.INTERNAL_SPACE
        : this.ct === ConvoType.whatsapp
          ? GroupType.WhatsApp
          : this.ct === ConvoType.support_center
            ? GroupType.SupportCenter
            : this.ct === ConvoType.call
              ? GroupType.call
              : null;
  }

  get isBotUser() {
    // currently hardcode for bot
    return this.user === '8f5fbd1c-0e00-11eb-adc1-0242ac120002';
  }

  markIsNoStore() {
    this.tf = setBit(this.tf, TransientFlag.IsNoStored);
    return this;
  }

  markIsRetry() {
    this.tf = setBit(this.tf, TransientFlag.IsRetry);
    return this;
  }
}

export class ChatMessage extends ChatMessageBase {
  buildMsg: BuildMessageUI = <BuildMessageUI>{};
  state: StateMessageUI = <StateMessageUI>{};
  aclActions: AclActionMessageUI = <AclActionMessageUI>{};
  attachmentUI: ChatMessageAttachmentUI = <ChatMessageAttachmentUI>{};
  previewMessageUI: PreviewMessageUI = <PreviewMessageUI>{};
  webhookUI: WebhookUI = <WebhookUI>{};
  userStateUI: UserStateUI = <UserStateUI>{};

  constructor(obj?: Partial<ChatMessage>) {
    super(obj);
    if (obj) {
      // clone object
      if (obj?.buildMsg) this.buildMsg = <BuildMessageUI>{ ...obj.buildMsg };
      if (obj?.state) this.state = <StateMessageUI>{ ...obj.state };
      if (obj?.aclActions) this.aclActions = <AclActionMessageUI>{ ...obj.aclActions };
      if (obj?.attachmentUI) this.attachmentUI = <ChatMessageAttachmentUI>{ ...obj.attachmentUI };
      if (obj?.previewMessageUI) this.previewMessageUI = <PreviewMessageUI>{ ...obj.previewMessageUI };
      if (obj?.webhookUI) this.webhookUI = <WebhookUI>{ ...obj.webhookUI };
      if (obj?.userStateUI) this.userStateUI = <UserStateUI>{ ...obj.userStateUI };

      // new instance for body msg by mt
      if (this.body) {
        // convert specific case
        if (this.mt === MsgType.attachment) {
          if ((<AttachmentMessageDataV2>this.body.data)?.attachment) {
            this.body.data = <AttachmentMessageDataV2>{
              ...this.body.data,
              attachment: new AttachmentMessageData(this.body.data.attachment)
            };
          } else {
            this.body.data = new AttachmentMessageData(this.body.data);
          }
        } else if (this.mt === MsgType.email) {
          if (this.body.data && typeof this.body.data === 'object') {
            this.body.data = new EmailMessageGeneral(this.body.data);
          }
        } else if (this.mt === MsgType.imess) {
          this.state.isInteractiveV2 = !!(this.body?.data as IMessBodyData)?.components;
          if (this.state.isInteractiveV2) {
            // interactive
            this.body.data = new IMessBodyData(this.body.data);
          } else {
            // webhook
            this.body.data = new InteractiveMessageData(this.body.data);
          }
        } else if (this.mt === MsgType.waTemplate) {
          this.body.data = new WaTemplateMessageData(this.body.data);
        }
      }

      this.initUI();
      this.initUserState();
    }
  }

  static createMessage(
    convo: ConversationGroup | Channel | ChannelHyperspace,
    body: MessageBody,
    user: string,
    mt: MsgType
  ) {
    const instance = new ChatMessage();

    instance.convo = convo.id; // chat system only know convo id but not convo group id. And currenlty only support public convo
    instance.body = body;
    instance.user = user;
    instance.ut = convo.userType;
    instance.ct = convo.convoType;
    instance.mt = mt;

    if (convo instanceof ChannelHyperspace) {
      instance.hs = convo.hyperspaceId;
    }

    return instance;
  }

  static createEmailMessage(convo: ConversationGroup, body: MessageBody, user: string, mt: MsgType, isNoStore = false) {
    const instance = new ChatMessage();

    instance.convo = convo.publicConversationId;
    instance.body = body;
    instance.user = user;
    instance.ut = convo.userType;
    instance.ct = convo.convoType;
    instance.mt = mt;
    if (isNoStore) {
      instance.markIsNoStore();
    }
    return instance;
  }

  static createMessagePublic(convo: ConversationGroup, body: MessageBody, user: string, mt: MsgType) {
    const instance = new ChatMessage();
    instance.convo = convo.conversationGroupId;
    instance.body = body;
    instance.user = user;
    instance.ut = UserType.Customer;
    instance.ct = convo.convoType;
    instance.mt = mt;
    return instance;
  }

  static createSystemMessage(
    convo: ConversationGroup | Channel | ChannelHyperspace,
    body: MessageBody,
    user: string,
    st: SystemType
  ) {
    const instance = new ChatMessage();
    instance.convo = convo.id; // chat system only know convo id but not convo group id. And currenlty only support public convo
    instance.ut = convo.userType;
    instance.body = body;
    instance.user = user;
    instance.ct = convo.convoType;
    instance.st = st;
    instance.mt = MsgType.system;

    if (convo instanceof ChannelHyperspace) {
      instance.hs = convo.hyperspaceId;
    }

    return instance;
  }

  static createSeenMessage(convo: ConversationGroup | Channel | ChannelHyperspace) {
    const instance = new ChatMessage();
    instance.convo = convo.id; // chat system only know convo id but not convo group id. And currenlty only support public convo
    instance.ut = convo.userType;
    instance.ts = 0;

    instance.body = new MessageBody();
    instance.ct = convo.convoType;
    instance.st = SystemType.SEEN;
    instance.mt = MsgType.system;
    instance.markIsNoStore();

    if (convo instanceof ChannelHyperspace) {
      instance.hs = convo.hyperspaceId;
    }

    return instance;
  }

  static createEmailSeenMessage(convo: ConversationGroup) {
    const instance = new ChatMessage();
    instance.convo = convo.publicConversationId; // chat system only know convo id but not convo group id. And currenlty only support public convo
    instance.ut = convo.userType;

    instance.body = new MessageBody();
    instance.ct = convo.convoType;
    instance.st = SystemType.SEEN;
    instance.mt = MsgType.system;
    instance.markIsNoStore();
    return instance;
  }

  static createDeleteMessage(message: ChatMessage) {
    const instance = new ChatMessage(message);
    instance.body = new MessageBody({});
    instance.mt = MsgType.system;
    instance.st = SystemType.DELETE;
    return instance;
  }

  toJSONString(): string {
    const cloned = Object.assign({}, this);
    if (cloned.body.data !== null && typeof cloned.body.data === 'object') {
      cloned.body.data = JSON.stringify(cloned.body.data);
    }
    return JSON.stringify(cloned);
  }

  private initUI() {
    if (this.buildMsg.newByLastMessageChannel) {
      this.buildMsg.builtTextForLastMessageConvo = buildMarkdownMessage(this, <InfoMarkdown>{
        hyperId: this.hs,
        noCode: true,
        noSingleCode: false
      });
    }

    const isTeamChat = [ConvoType.direct, ConvoType.groupchat, ConvoType.THREAD].includes(this.ct);
    const isInternalSpace = ConvoType.INTERNAL_SPACE === this.ct;

    this.aclActions = <AclActionMessageUI>{
      ...this.aclActions,
      isDeletedMessage: !!this.metadata?.deletedAt || this?.st === SystemType.DELETE,
      isEditedMessage: !!this.metadata?.editedAt && this.metadata?.editedAt > 0,
      allowUseAddBookmark:
        !!this.id &&
        this.ct !== ConvoType.personal &&
        isTeamChat &&
        [MsgType.attachment, MsgType.message].includes(this.mt),
      allowUseReplyAction:
        !!this.id && isTeamChat && this.mt !== MsgType.system && ![BotType.robin].includes(this.userStateUI.botType),
      allowUseCreateThreadAction:
        !!this.id &&
        !this.hasThread &&
        this.ct === ConvoType.groupchat &&
        [MsgType.message, MsgType.attachment].includes(this.mt),
      allowEditMessage:
        !!this.id &&
        !this.hasThread &&
        (isTeamChat || isInternalSpace) &&
        this.mt === MsgType.message &&
        ![BotType.robin].includes(this.userStateUI.botType),
      allowDeleteMessage:
        !!this.id &&
        !this.hasThread &&
        (isTeamChat || isInternalSpace) &&
        this.mt !== MsgType.system &&
        ![BotType.robin].includes(this.userStateUI.botType),
      allowPinOrUnpinAction: !!this.id && isTeamChat && [MsgType.message, MsgType.attachment].includes(this.mt)
    };
    this.state = <StateMessageUI>{
      ...this.state,
      isNotified: this.IsNotified
    };

    if (this.aclActions.isDeletedMessage) {
      this.body = new MessageBody({
        ...this.body,
        text: 'Deleted message'
      });
    }

    this.state.isCustomerTxn =
      [
        ConvoType.whatsapp,
        ConvoType.sms,
        ConvoType.LIVECHAT,
        ConvoType.INTERNAL_SPACE,
        ConvoType.call,
        ConvoType.email
      ].includes(this.ct) && this.ut !== UserType.Agent;
    this.buildMsg.displayTime = format(this.ts, 'HH:mm');
    this.buildMsg.fullTime = format(this.ts, 'MMM dd, yyyy, HH:mm:ss');

    if (!this.aclActions.isDeletedMessage) {
      const msgTypsSupported = MSG_TYPE_BUILDER?.[this.mt];
      if (msgTypsSupported) {
        msgTypsSupported(this);
      } else {
        // todo: mt not supported
        // show unrenderable msg
      }
    }
  }

  private initUserState() {
    if (this.user && !this.userStateUI.identityUuid && ![SYSTEM_IDENTITY].includes(this.user)) {
      try {
        const [orgUuid, identityUuid] = decodeUserId(this.user);
        this.userStateUI.identityUuid = identityUuid;
      } catch (error) {
        this.userStateUI.identityUuid = '';
      }
    }

    if (this.userStateUI.identityUuid === ROBIN_BOT_IDENTITY) {
      this.userStateUI.icon = 'school';
      this.userStateUI.isBot = true;
      this.userStateUI.botType = BotType.robin;
    } else if (this.user === SYSTEM_IDENTITY) {
      this.userStateUI.icon = 'smart_toy';
      this.userStateUI.isBot = true;
      this.userStateUI.botType = BotType.omni;
    } else {
      this.userStateUI.icon = 'person';
    }
  }
}

export class MessageBody {
  text: string; // for searching on ES
  title: string; // for notification. if empty title equals text
  data:
    | any
    | string // string for pointer message.
    | PreviewMessageData
    | AttachmentMessageData
    | AttachmentMessageDataV2
    | InteractiveMessageData
    | EmailMessageGeneral
    | PreChatSurvey
    | ResponseTxn
    | IMessBodyData
    | CaseActivity

    // with type is required
    | SystemMessageData
    | TxnMessageData
    | TxnMoveInboxData // SystemMsgType.moveInbox
    | WaTemplateMessageData; // msgType = waTemplate

  constructor(obj?: Partial<MessageBody>) {
    if (obj) {
      Object.assign(this, obj);
      if (typeof obj['data'] === 'string' && !!obj['data']) {
        try {
          this.data = JSON.parse(obj['data']);
        } catch (error) {
          // TODO for pointer data
          this.data = obj['data'];
          console.error('typeof obj[data] === string: ', this);
        }
      }
      if (this.text) {
        // progess text message
      }
    }
  }
}

export class Metadata {
  content: ChatMessage;
  editedAt: number;
  deletedAt: number;
  threadId: string;

  constructor(obj?: Partial<Metadata>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj.editedAt) this.editedAt = +obj.editedAt;
      if (obj['edited_at']) this.editedAt = +obj?.['edited_at'];
      if (obj.deletedAt) this.deletedAt = +obj.deletedAt || +obj?.['deleted_at'];
      if (obj['deleted_at']) this.deletedAt = +obj?.['deleted_at'];
    }
  }
}
