<h1 mat-dialog-title>Call Log detail</h1>

<div mat-dialog-content>
  <form class="flex !flex-col gap-[16px] items-stretch justify-start">
    <div class="flex items-start justify-start gap-[16px]">
      <div class="flex flex-auto !flex-col gap-[8px]">
        <div class="flex-auto"><strong>From:</strong> {{ call.callerId }}</div>
        <div class="flex-auto"><strong>Agent:</strong> {{ call.agentString }}</div>
        <div class="flex-auto"><strong>Status:</strong> {{ call.status }}</div>
      </div>
      <div class="flex-auto flex !flex-col gap-[8px]">
        <div class="flex-auto"><strong>At:</strong> {{ call.timestamp | userDate }}</div>
        <div class="flex-auto"><strong>Queue:</strong> {{ call.queueName }}</div>
        <div class="flex-auto"><strong>Duration:</strong> {{ call.durations.talking | duration }}</div>
      </div>
    </div>
    <div *ngIf="orderInfoArr.length > 0">
      <p><strong>Order info:</strong></p>
      <ul>
        <li *ngFor="let item of orderInfoArr">{{ item.key }}: {{ item.value }}</li>
      </ul>
    </div>
    <div>
      <mat-form-field class="full-width !w-full">
        <mat-select placeholder="Disposition code" [(ngModel)]="call.code" name="dispositionCode" #callerId="ngModel">
          <mat-option [value]="">No deposition code</mat-option>
          <mat-option *ngFor="let code of codes" [value]="code">{{ code }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <textarea class="changeable-textbox" matInput placeholder="Note" [(ngModel)]="call.note" rows="3"></textarea>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="onUpdateNote()">
    <span *ngIf="!isUpdating">Update</span> <span *ngIf="isUpdating">Updating...</span>
  </button>
</div>
