import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove } from '@datorama/akita';
import { RelatedTxn } from './related-txn.model';
import { RelatedTxnsQuery } from './related-txn.query';
import { RelatedTxnsStore } from './related-txn.store';

@Injectable({
  providedIn: 'root'
})
export class RelatedTxnsService {
  constructor(
    private store: RelatedTxnsStore,
    private query: RelatedTxnsQuery
  ) {}

  addRelated(txnUuid: string, identityUuids: string[]) {
    const entity = this.query.getEntity(txnUuid);
    if (entity) {
      this.store.update(txnUuid, ({ mentions }) => ({
        mentions: arrayAdd(mentions, identityUuids)
      }));
    } else {
      const mentions: RelatedTxn = {
        txnUuid: txnUuid,
        mentions: identityUuids
      } as RelatedTxn;
      this.store.upsertMany([mentions]);
    }
  }

  removeRelated(txnUuid: string, identityUuids: string[]) {
    this.store.update(txnUuid, ({ mentions }) => ({
      mentions: arrayRemove(mentions, identityUuids)
    }));
  }
}
