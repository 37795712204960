<h1 mat-dialog-title>Incoming call from {{ call.callerId }}</h1>

<div mat-dialog-content>
  <div class="flex items-start justify-start">
    <div class="w-[70%]">
      <div class="flex">
        <mat-form-field appearance="outline">
          <mat-label>From</mat-label>
          <input matInput value="{{ call.callerId }}" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Queue</mat-label>
          <input matInput value="{{ call.queueName || '-' }}" />
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="example-full-width">
          <textarea matInput placeholder="Script"></textarea>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field appearance="outline">
          <mat-label>Disposition Code</mat-label>
          <input matInput value="{{ call.code || '-' }}" />
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="example-full-width">
          <textarea matInput rows="5" placeholder="Note" value="{{ call.note }}"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="w-[30%]">
      <h4 mat-subheader>LASTEST 5 CALLS</h4>
      <div *ngFor="let call of lastest5Calls">
        <div><strong>At:</strong>{{ call.first.talking.timestamp | userDate }}</div>
        <div><strong>Status:</strong>{{ call.status }}</div>
        <div><strong>Disposition Code:</strong>{{ call.code }}</div>
        <div><strong>Note:</strong>{{ call.note }}</div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end"><button mat-button mat-dialog-close>Close</button></div>
