import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Watchers, WatcherUI } from './watchers.model';
import { WatchersState, WatchersStore, WatcherUIState } from './watchers.store';

@Injectable({ providedIn: 'root' })
export class WatchersQuery extends QueryEntity<WatchersState> {
  override ui: EntityUIQuery<WatcherUIState>;

  constructor(protected override store: WatchersStore) {
    super(store);
    this.createUIQuery();
  }

  getUiState(txnUuid: string | ID) {
    return this.ui.getEntity(txnUuid);
  }

  selectWatcherByTxn(txnUuid: string) {
    return this.selectEntity(txnUuid);
  }

  selectTxnsWatchingByMe(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => entity?.watchers?.includes(meIdentity)
    });
  }

  selectPropertyTxn<K extends keyof Watchers>(id: string, property: K) {
    return this.selectEntity(id, property);
  }

  selectPropertyFuncTxn<R>(id: string, project: (entity?: Watchers) => R): Observable<R> {
    return this.selectEntity(id, project);
  }

  selectUIState<K extends keyof WatcherUI>(id: string | ID, property: K) {
    return this.ui.selectEntity(id, property);
  }
}
