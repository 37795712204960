<h1 mat-dialog-title>Assign queues</h1>
<ng-container *ngIf="checkProficiency == true">
  <p class="text-red-500 px-[24px] pb-[8px]">
    Please make sure all agents are given the proficiency values in order to receive calls
  </p>
</ng-container>
<div mat-dialog-content>
  <form #form="ngForm" class="!flex !flex-col items-stretch justify-stretch gap-[16px]">
    <mat-form-field class="!w-full">
      <mat-label>Select queues</mat-label>
      <mat-select
        [(ngModel)]="selectedQueues"
        multiple
        name="selectedQueues"
        placeholder="Select queues"
        required
        #selectedQueuesCtr="ngModel"
        (ngModelChange)="onChange($event)"
      >
        <mat-option *ngFor="let queue of queues" [value]="queue.uuid">{{ queue.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="assign()">Assign</button>
</div>
