import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { IdentityProfile, IdentityProfileService, ProfileOrg, Team } from '@b3networks/api/auth';
import { Extension } from '@b3networks/api/bizphone';
import { ExtensionService } from '@b3networks/api/callcenter';
import { PortalConfig } from '@b3networks/api/partner';
import { PersonalSettingsService } from '@b3networks/api/portal';
import { SessionQuery } from '@b3networks/portal/base/shared';
import { B3_ORG_UUID, B3_UAT_ORG_UUID, EventMapName, SharedCommonModule } from '@b3networks/shared/common';
import { ThemeService } from '@b3networks/shared/ui/theme';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, combineLatest, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LogoutDlg } from '../../shared/modal/logout/logout.component';
import { SwitchOrganizationDialog } from '../../shared/modal/switch-org/switch-org.component';
import { WindowMessageService } from '../../shared/service/window-message.service';

export interface SidebarData {
  profile: IdentityProfile;
  currentOrg: ProfileOrg;
  darkMode: boolean;
  hasServicedOrg: boolean;
  servicedOrgs: ProfileOrg[];
}

interface TeamAndExtData {
  teams: Team[];
  ext: Extension;
}

@Component({
  selector: 'b3n-user-profile-menu',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTooltipModule,
    SharedCommonModule,
    ClipboardModule
  ],
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit {
  @Input() data: SidebarData;
  @Input() isDarkMode$: Observable<boolean>;
  @Input() currentOrgUuid: string;
  @Input() hasStore$: Observable<boolean>;
  @Input() portalConfig$: Observable<PortalConfig>;

  teamAndExtData$: Observable<TeamAndExtData>;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private personalSettingService: PersonalSettingsService,
    private windowService: WindowMessageService,
    private identityProfileService: IdentityProfileService,
    private extensionService: ExtensionService,
    private sessionQuery: SessionQuery
  ) {}

  ngOnInit() {
    this.teamAndExtData$ = combineLatest([this.sessionQuery.profile$, this.sessionQuery.currentOrg$]).pipe(
      switchMap(([profile, _]) => {
        return combineLatest([
          this.identityProfileService.getTeams(profile.uuid).pipe(catchError(_ => of(null))),
          this.extensionService.getMe().pipe(catchError(_ => of({} as Extension)))
        ]);
      }),
      map(([teams, ext]) => {
        return {
          teams: teams,
          ext: ext
        } as TeamAndExtData;
      })
    );
  }

  showOrgChartMenu() {
    return this.currentOrgUuid === B3_ORG_UUID || this.currentOrgUuid === B3_UAT_ORG_UUID;
  }

  showSwitchOrganizationDialog() {
    this.dialog
      .open(SwitchOrganizationDialog, {
        width: '500px',
        autoFocus: false,
        position: {
          top: '40px'
        },
        panelClass: this.themeService.isDarkMode ? 'dark-theme-portal-base' : null
      })
      .afterClosed()
      .subscribe();
  }

  gotoManageConnections() {
    this.router.navigate([this.currentOrgUuid, 'manage-connection']);
  }

  gotoManageProfile() {
    this.router.navigate([this.currentOrgUuid, 'account']);
  }

  gotoOrganizationChart() {
    this.router.navigate([this.currentOrgUuid, 'org-chart']);
  }

  gotoManageOrganization() {
    this.router.navigate([this.currentOrgUuid, 'manage-organization']);
  }

  gotoStore() {
    this.router.navigate([this.currentOrgUuid, 'Store']);
  }

  copied() {
    this.toastService.success('Copied to clipboard');
  }

  toggleDarkMode(dartMode: boolean, $event?: Event) {
    if ($event) {
      $event.stopPropagation();
    }

    this.personalSettingService.updateDarkMode(!dartMode).subscribe(res => {
      this.windowService.fireEventAllRegisters(EventMapName.updateDarkMode, res.darkMode, this.currentOrgUuid);
    });
  }

  openLogoutDialog() {
    this.dialog.open(LogoutDlg, {
      width: '500px',
      panelClass: this.themeService.isDarkMode ? 'dark-theme-portal-base' : null,
      autoFocus: false
    });
  }
}
