<mat-progress-bar *ngIf="fetching" class="my-[10px]" mode="indeterminate"></mat-progress-bar>

<ng-container *ngIf="settings$ | async as settings">
  <mat-toolbar-row class="action-bar flex items-center justify-start gap-[8px]">
    <span class="call-counter-text"> {{ callLog?.length || '0' }} call<span *ngIf="callLog?.length > 1">s</span> </span>

    <button *ngIf="!fetching" mat-icon-button (click)="pollingData(settings)" matTooltip="Refresh">
      <mat-icon>autorenew</mat-icon>
    </button>

    <mat-progress-spinner *ngIf="fetching" strokeWidth="2" mode="indeterminate" diameter="20"></mat-progress-spinner>

    <span class="flex-auto"></span>

    <mat-form-field class="no-validator">
      <mat-label>Auto refresh</mat-label>
      <mat-select
        [(ngModel)]="settings.workspaceActiveCall.autoRefreshTime"
        [compareWith]="compareCodeFn"
        (selectionChange)="filterChanged(settings)"
        placeholder="Auto refresh"
      >
        <mat-option *ngFor="let refresh of refreshes" [value]="refresh.key"> {{ refresh.value }} </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-toolbar-row>

  <div *ngIf="callLog" class="callcenter">
    <table mat-table [dataSource]="callLog" class="active-calls-table">
      <ng-container matColumnDef="txn-uuid">
        <th mat-header-cell *matHeaderCellDef>Txn. Uuid</th>
        <td mat-cell *matCellDef="let call">
          <button
            class="box-uuid"
            mat-button
            matTooltip="Click to copy"
            [cdkCopyToClipboard]="call.txnUuid"
            (cdkCopyToClipboardCopied)="copied()"
          >
            {{ call.txnUuid | slice: 0 : 8 }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef>From</th>
        <td mat-cell *matCellDef="let call">{{ call.fromDisplay }}</td>
      </ng-container>

      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef>To</th>
        <td mat-cell *matCellDef="let call">
          {{ call.toDisplay }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let call">
          <span>{{ call.type | camel2title }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let call">
          <span class="status" *ngIf="!call.onTransferCall && !call.onTransferCall2Queue">{{
            call.status | camel2title
          }}</span>
          <span class="status" *ngIf="call.onTransferCall || call.onTransferCall2Queue">Transfering</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="start-at">
        <th mat-header-cell *matHeaderCellDef>Start At</th>
        <td mat-cell *matCellDef="let call">{{ call.startedAt | userDate }}</td>
      </ng-container>

      <ng-container matColumnDef="talking-at">
        <th mat-header-cell *matHeaderCellDef>Talking At</th>
        <td mat-cell *matCellDef="let call">{{ call.talkingAt | userDate }}</td>
      </ng-container>

      <ng-container matColumnDef="queue">
        <th mat-header-cell *matHeaderCellDef>Queue</th>
        <td mat-cell *matCellDef="let call">{{ call.queueLabel }}</td>
      </ng-container>

      <ng-container matColumnDef="agent">
        <th mat-header-cell *matHeaderCellDef>Agent</th>
        <td mat-cell *matCellDef="let call">{{ call.assignedAgentString }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let call">
          <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="{ call: call }">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        [ngClass]="{ hide: callLog.length === 0 }"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <h3 class="flex !flex-col items-center justify-center">No active calls</h3>
        </td>
      </tr>
    </table>
  </div>

  <mat-menu #actionMenu="matMenu">
    <ng-template matMenuContent let-call="call">
      <div *ngIf="call.isTalking">
        <button mat-menu-item *ngFor="let action of takeActions" (click)="doAgentAction(call, action, settings)">
          {{ action.value }}
        </button>
      </div>
      <button mat-menu-item (click)="doAgentAction(call, forceHangup, settings)">{{ forceHangup.value }}</button>
    </ng-template>
  </mat-menu>
</ng-container>
