export class Partner {
  partnerUuid: string;
  type: string;
  domainOwnerName: string;
  portalName: string;
  salesEmail: string;
  logoUrl: string | ArrayBuffer;
  supportedCurrencies: string[];
  faviconUrl: string | ArrayBuffer;
  partnerName: string;
  domainName: string;
  portalTitle: string;
  supportEmail: string;
  loginHeaderBackground: string;
  loginButtonBackground: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

export interface VerifiedSender {
  domain: string;
  sender: string;
}

export class PartnerDomain {
  domain: string;
  partnerUuid: string;
  type: string;
  partnerName: string;
  portalName: string;
  senderEmail: string;
  supportEmail: string;
  salesEmail: string;
  logoUrl: string;
  faviconUrl: string;
  supportedCurrencies: string[];

  constructor(value: Object) {
    this.salesEmail = '';
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key) && value[key] != null) {
          this[key] = value[key];
        }
      }
    }
  }
}
